// src/views/DirtForm/MaterialTypePage/index.jsx

import '../index.css';
import { useState, useContext, useEffect } from 'react';
import { Pane, Select, TextInputField, RadioGroup, Button, Heading } from 'evergreen-ui';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useNavigate } from 'react-router-dom';
import HelpLink from '../../../components/HelpLink';
import { DirtFormContext } from '../../../contexts/DirtFormContext';

const MaterialTypePage = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(DirtFormContext);
  
  const [material, setMaterial] = useState(formData.material || '');
  const [unit, setUnit] = useState(formData.unit || 'cubic');
  const [quantity, setQuantity] = useState(formData.quantity || '');
  const [price, setPrice] = useState(formData.price || '');
  const [pricingOption, setPricingOption] = useState(formData.pricingOption || 'total');
  const [needsTruck, setNeedsTruck] = useState(formData.needsTruck ? 'true' : 'false');
  const [needsDigger, setNeedsDigger] = useState(formData.needsDigger ? 'true' : 'false');

  const handleSubmit = () => {
    setFormData(prevData => ({
      ...prevData,
      material,
      unit,
      quantity: Number(quantity),
      price: Number(price),
      needsTruck: needsTruck === 'true',
      needsDigger: needsDigger === 'true'
    }));
    navigate('/dirt/images');
  };

  return (
    <div className="row">
      <div className="col text-center">
        {/* Heading row */}
        <div className="row">
          <div className="col text-start">
            <h2>Material Type</h2>
          </div>
        </div>
        {/* Subheading row */}
        <div className="row mt-2">
          <div className="col text-start">
            <p>Enter the material details</p>
          </div>
        </div>
        {/* Form elements */}
        <div className="row mt-4">
          <div className="col text-start">
            <Select value={material} onChange={e => setMaterial(e.target.value)} width="100%">
              <option value="" disabled>Select A Material Type</option>
              <option value="topsoil-ungraded">Topsoil ungraded</option>
              <option value="topsoil-graded">Topsoil graded</option>
              <option value="crusher-dust">Crusher dust</option>
              <option value="clay">Clay</option>
              <option value="clay-premium">Clay premium</option>
              <option value="clay-engineered">Clay engineered</option>
              <option value="mixed-fill">Mixed fill (Soil & clay)</option>
              <option value="large-rocks-0-50kg">Large rocks (0-50kg)</option>
              <option value="large-rocks-50-100kg">Large rocks (50-100kg)</option>
              <option value="large-rocks-100-250kg">Large rocks (100-250kg)</option>
              <option value="large-rocks-250kg-plus">Large rocks (250kg+)</option>
              <option value="silt">Silt</option>
              <option value="sand">Sand</option>
              <option value="gravel">Gravel</option>
              <option value="crushed-stone">Crushed Stone</option>
              <option value="all-purpose-rocks">All purpose rocks (AP)</option>
              <option value="drainage-scoria">Drainage scoria</option>
              <option value="fill-dirt">Fill Dirt</option>
              <option value="limestone">Limestone</option>
              <option value="granite">Granite</option>
              <option value="marble">Marble</option>
              <option value="slate">Slate</option>
              <option value="sandstone">Sandstone</option>
              <option value="quartzite">Quartzite</option>
              <option value="dolomite">Dolomite</option>
              <option value="gypsum">Gypsum</option>
              <option value="basalt">Basalt</option>
              <option value="flint">Flint</option>
              <option value="chalk">Chalk</option>
              <option value="shale">Shale</option>
              <option value="peat">Peat</option>
              <option value="coal">Coal</option>
            </Select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <RadioGroup
              label="Choose the unit of measurement"
              value={unit}
              options={[
                { label: 'Cubic meters (m³)', value: 'cubic' },
                { label: 'Tons (t)', value: 'ton' }
              ]}
              onChange={event => setUnit(event.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <TextInputField
              label={<span style={{ fontFamily: 'inherit', color: '#425A70' }}>How much material is there?</span>}
              placeholder='Enter the quantity'
              type="number"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <p style={{ fontFamily: 'inherit', color: 'inherit' }}>Do you need us to supply equipment?</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-start">
            <RadioGroup
              label={<span style={{ fontFamily: 'inherit', color: '#425A70' }}>Do you need a truck?</span>}
              value={needsTruck}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
              onChange={event => setNeedsTruck(event.target.value)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <RadioGroup
              label="Do you need a digger?"
              value={needsDigger}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ]}
              onChange={event => setNeedsDigger(event.target.value)}
            />
          </div>
        </div>
        {/* Help link */}
        <div className="row mt-4">
          <div className="col text-start">
            <HelpLink onClick={() => console.log('Help clicked')} />
          </div>
        </div>
        {/* Next button row */}
        <div className="row mt-4 justify-content-start">
                <div className="col-12 col-md-4 custom-width-35">
            <Button className="btn btn-warning w-100" onClick={handleSubmit}>
              Next <span className="ml-2"></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTypePage;
