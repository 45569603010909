import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const BreadcrumbContainer = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

const BreadcrumbLink = styled(Link)`
  color: #696f8c;
  text-decoration: none;
  font-size: 0.875rem;

  &:not(:last-child)::after {
    content: '>';
    margin: 0 8px;
    color: #696f8c;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Breadcrumb = ({ paths }) => {
  return (
    <BreadcrumbWrapper>
      <BreadcrumbContainer>
        {paths.map((path, index) => (
          <BreadcrumbLink key={index} to={path.url}>
            {path.name}
          </BreadcrumbLink>
        ))}
      </BreadcrumbContainer>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
