import React from "react";
import "./HomePage.css";
import Header from '../../components/header';
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import LearnMoreSectionBuySell from '../../components/LearnMoreSectionBuySell';
import LearnMoreSectionEquipment from '../../components/LearnMoreSectionEquipment';
import LearnMoreSectionSiteAssess from '../../components/LearnMoreSectionSiteAssess';
import LearnMoreSection from '../../components/LearnMoreSection';

export const Dirt = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/dirt/list');
  };

  return (
    <div>
      <Header />
      <div className="dirt" style={{ backgroundColor: '#ffffff' }}>
        <div className="container">
          {/* Hero Section */}
          <div className="hero-container">
            <div className="hero-content">
              <div className="hero-text text-start">
                <h1 style={{ fontSize: '28px' }}>Meet Dirt</h1>
                <p>We Move, Buy, &amp; Sell Earth Materials.</p>
                <p>
                  At Dirt, we simplify the process of buying, selling, and moving earth materials
                  like soil, rocks, and clay by connecting you with people who have the materials
                  you need or want the ones you're removing.
                </p>
                <button className="hero-button" onClick={handleNavigation}>
                  Get Started
                </button>
              </div>
              <div className="hero-image">
                <img
                  src={`${process.env.PUBLIC_URL}/hero-explainer.svg`}
                  alt="Dirt Moving Machine"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="section-spacer" style={{ '--spacer-height': '40px' }}></div>

          <LearnMoreSection />

          <div className="section-spacer" style={{ '--spacer-height': '60px' }}></div>

          {/* Material Management Section */}
          <div className="material-management-section">
            <img
              src={`${process.env.PUBLIC_URL}/hero-site-grass-topsoil.svg`}
              alt="Material Management Hero"
              className="material-management-hero-image"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/hero-card-materials-design.svg`}
              alt="Hero Card Materials Design"
              className="hero-card-materials-design"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/removal-supply-button.svg`}
              alt="Material Requirements Button"
              className="material-requirements-button"
              loading="lazy"
            />
            <h1 className="heading-text">
              Residential & Commercial Material Management
            </h1>
          </div>

          <div className="section-spacer"></div>

          <LearnMoreSectionSiteAssess className="mt-4" />

          <div className="section-spacer"></div>

          {/* Material Assessment Section */}
          <div className="material-assessment-section">
            <img
              src={`${process.env.PUBLIC_URL}/material-assessment-hero.svg`}
              alt="Material Assessment Hero"
              className="material-assessment-hero-image"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/material-requirements-button.svg`}
              alt="Material Requirements Button"
              className="material-requirements-button"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/plans-hero-card-design.svg`}
              alt="Plans Hero Card Design"
              className="plans-hero-card"
              loading="lazy"
            />
            <h1 className="heading-text">
              Site Material Planning & Assessment
            </h1>
          </div>

          <div className="section-spacer"></div>

          <LearnMoreSectionEquipment className="mt-4" />

          <div className="section-spacer"></div>

          {/* Equipment Supply Section */}
          <div className="equipment-supply-section">
            <img
              src={`${process.env.PUBLIC_URL}/equipment-supply-hero.svg`}
              alt="Equipment Supply Hero"
              className="equipment-supply-hero-image"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/equipment-supply.svg`}
              alt="Material Requirements Button"
              className="material-requirements-button"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/loader-image.svg`}
              alt="Loader Image"
              className="loader-image"
              loading="lazy"
            />
            <h1 className="heading-text">
              Excavation & Transport Equipment Supply
            </h1>
          </div>

          <div className="section-spacer"></div>

          <LearnMoreSectionBuySell className="mt-4" />

          <div className="section-spacer"></div>

          {/* Dirt Background Section */}
          <div className="dirt-background-section">
            <img
              src={`${process.env.PUBLIC_URL}/dirt-background.svg`}
              alt="Dirt Background"
              className="dirt-background-image"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/sale-puchase-button.svg`}
              alt="Sale Purchase Button"
              className="material-requirements-button"
              loading="lazy"
            />
            <img
              src={`${process.env.PUBLIC_URL}/selection-card-dirt-requests.svg`}
              alt="Selection Card Dirt Requests"
              className="selection-card"
              loading="lazy"
            />
            <h1 className="heading-text">Buy, Sell, Source Materials</h1>
          </div>

          {/* The Dirt Process Section */}
          <div className="core-values-container my-4">
            <h2>Dirt Process</h2>
            <div className="core-values-content text-start">
              <div className="core-value">
                <img
                  src={`${process.env.PUBLIC_URL}/dirt-listing.svg`}
                  alt="Sign Up"
                  loading="lazy"
                />
                <h3>Sign Up</h3>
                <p>Provide your details to get started.</p>
              </div>
              <div className="core-value">
                <img
                  src={`${process.env.PUBLIC_URL}/dirt-profile.svg`}
                  alt="List Materials"
                  loading="lazy"
                />
                <h3>List Materials</h3>
                <p>Specify the materials you need or offer.</p>
              </div>
              <div className="core-value">
                <img
                  src={`${process.env.PUBLIC_URL}/dirt-transport.svg`}
                  alt="Transport & Delivery"
                  loading="lazy"
                />
                <h3>Transport & Delivery</h3>
                <p>We handle the logistics for you.</p>
              </div>
            </div>
          </div>

          {/* Dirt Business Section */}
          <div className="design-language-container">
            <h2>Dirt Business</h2>
            <div className="design-language-content">
              <div className="design-language-item">
                <h3>Cost-Saving Solutions</h3>
                <p>
                  Material costs and management are crucial for earthworks.
                  Partner with Dirt to access our network, reducing your job
                  costs by moving dirt at no cost or below market rates.
                </p>
                <a href="/about" className="link">
                  Contact Us
                </a>
              </div>
              <div className="design-language-item">
                <h3>Material Management</h3>
                <p>
                  Our large network finds you the materials you need, when you
                  need them, at the best price. Reducing your job costs by
                  moving dirt at no cost or below market rates.
                </p>
                <a href="/about" className="link">
                  Contact Us
                </a>
              </div>
              <div className="design-language-item">
                <h3>Reduce Expenses & Delays</h3>
                <p>
                  Moving, supplying, or disposing of Dirt can be costly and
                  time-consuming. Let us handle it, saving you money and time
                  while you focus on your project.
                </p>
                <a href="/about" className="link">
                  Contact Us
                </a>
              </div>
            </div>
          </div>

{/* Footer Callout Section */}
<div className="mainpage-footer-callout">
  <h2>Contact</h2>
  <p>
    Want to chat with a local team member and get more info? Contact or learn
    more below.
  </p>
  <div className="callout-links">
    <div className="callout-link">
      <img
        src={`${process.env.PUBLIC_URL}/dirt-profile.svg`}
        alt="Contact Us"
        style={{ marginBottom: '10px' }}
        loading="lazy"
      />
      <a href="/about" className="link">
        <h3>Contact us</h3>
      </a>
    </div>

    <div className="callout-link">
      <img
        src={`${process.env.PUBLIC_URL}/dirt-transport.svg`}
        alt="Learn More"
        style={{ marginBottom: '10px' }}
        loading="lazy"
      />
      <a href="/about" className="link">
        <h3>Learn More</h3>
      </a>
    </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
