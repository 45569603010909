// SignUpPage.jsx

import '../index.css';
import { useState, useContext } from 'react';
import { TextInputField, Button, Checkbox } from 'evergreen-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import HelpLink from '../../../components/HelpLink';
import axios from 'axios';
import { DirtFormContext } from '../../../contexts/DirtFormContext';

const SignUpPage = () => {
  const navigate = useNavigate();
  const location = useLocation(); // To capture state passed from the previous page
  const { formData: contextFormData } = useContext(DirtFormContext);

  // Define requestUrl directly based on environment
  const requestUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://www.dirt.nz' // Production API URL
      : 'http://localhost:4000'; // Development API URL

  // Extract formData from location.state or from context
  const formData = location.state?.requestData || contextFormData || {};

  console.log('Received formData in SignUpPage:', formData);
  console.log('process.env.NODE_ENV:', process.env.NODE_ENV); // Debugging line to check environment
  console.log('Using requestUrl:', requestUrl); // Debugging line to check requestUrl

  // Initializing form fields with formData
  const [firstName, setFirstName] = useState(formData.firstName || '');
  const [lastName, setLastName] = useState(formData.lastName || '');
  const [company, setCompany] = useState(formData.company || '');
  const [email, setEmail] = useState(formData.email || '');
  const [password, setPassword] = useState('');
  const [contactNumber, setContactNumber] = useState(formData.contactNumber || '');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle form submission
  const handleSignUp = async (e) => {
    e.preventDefault();

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    // Basic password validation
    if (password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    // Basic contact number validation (optional)
    const contactNumberRegex = /^[0-9]{7,15}$/; // Adjust as per your requirements
    if (!contactNumberRegex.test(contactNumber)) {
      setError('Please enter a valid contact number (7-15 digits).');
      return;
    }

    setIsSubmitting(true); // Set loading state
    setError('');

    try {
      // Step 1: Create User
      const userResponse = await axios.post(
        `${requestUrl}/api/auth/signup`,
        {
          firstName,
          lastName,
          company,
          email,
          password,
          contactNumber,
        },
        {
          withCredentials: true, // Include credentials (cookies) in the request
        }
      );

      if (userResponse.status === 201 || userResponse.status === 200) {
        const userData = userResponse.data.user;
        console.log('User registered and logged in:', userData);

        // Optionally, you can store user data in a global state or context here
        // For example, using DirtFormContext or another context

        // Step 2: Create Listing associated with the user (if formData exists)
        if (formData && Object.keys(formData).length > 0) {
          // Prepare listing data
          const listingData = {
            ...formData,
            // 'requester' will be set by the backend using req.user._id
          };

          try {
            // Ensure that listingData is structured under 'requestInformation'
            const listingResponse = await axios.post(
              `${requestUrl}/api/listings`,
              {
                requestInformation: listingData,
              },
              {
                withCredentials: true, // Include credentials in the request
              }
            );

            if (listingResponse.status === 201 || listingResponse.status === 200) {
              console.log('Listing created successfully:', listingResponse.data);
              // Redirect to AllListingsPage with a success state
              navigate('/listings', { state: { success: true } });
            } else {
              console.error('Unexpected response during listing creation:', listingResponse);
              setError('Failed to create listing. Please try again.');
            }
          } catch (listingError) {
            console.error('Error creating listing:', listingError);
            setError('An error occurred while creating the listing. Please try again.');
          }
        } else {
          // If no listing data was passed, redirect to AllListingsPage
          navigate('/listings', { state: { success: true } });
        }
      } else {
        console.error('Unexpected response during user sign-up:', userResponse);
        setError('Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      // Handle specific error messages from the backend if available
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('An error occurred during sign up. Please try again.');
      }
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  return (
    <div className="container-lg pt-2" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 form-wrapper">
          <div className="row">
            <div className="col text-start">
              <h2>Create Your Materials Account</h2>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-start">
              <p>Sign up to view material listings in your area.</p>
            </div>
          </div>
          <form onSubmit={handleSignUp}>
            <div className="row mt-4">
              <div className="col text-start">
                <TextInputField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Company Name (Optional)"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company Name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Password"
                  type={showPassword ? 'text' : 'password'} // Toggle password visibility
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <Checkbox
                  label="Show Password"
                  checked={showPassword}
                  onChange={(e) => setShowPassword(e.target.checked)} // Toggle show password state
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Best Contact Number"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  placeholder="Best Contact Number"
                  required
                />
              </div>
            </div>
            {error && (
              <div className="row mt-2 text-start">
                <div className="col">
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col text-start">
                <HelpLink onClick={() => console.log('Help clicked')} />
              </div>
            </div>
            <div className="row mt-4 justify-content-start">
              <div className="col-12 col-md-4">
                <Button
                  className="btn form-card-button w-100"
                  type="submit"
                  appearance="primary"
                  intent="success"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                </Button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <Button
                  className="btn btn-minimal w-100"
                  appearance="minimal"
                  onClick={() => navigate('/login')}
                >
                  Already have an account? Log in here
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
