import Header from "../../components/header"
import { Outlet, ScrollRestoration } from 'react-router-dom';
import Footer from "../../components/Footer";
import { AuthContext } from "contexts/AuthContext";
import { useContext } from "react";

export default function ListingLayout() {

    const { user, loading } = useContext(AuthContext);

    console.log(user)

    return (
        <div>
            <Header />
            <Outlet />
            <Footer />
            <ScrollRestoration />
        </div>
        
    )
}