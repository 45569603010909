import React, { createContext, useState, useEffect } from 'react';
import axios from '../axiosInstance'; // Ensure this path is correct

// Create the AuthContext
export const AuthContext = createContext();

/**
 * AuthProvider Component
 * 
 * This component provides authentication state and methods to its children.
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Initialize the user state with data from localStorage, if available
    const storedUser = localStorage.getItem('authUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [loading, setLoading] = useState(true); // Indicates if the authentication status is being determined

  useEffect(() => {
    // Define requestUrl based on environment
    const requestUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://www.dirt.nz' // Production API URL
        : 'http://localhost:4000'; // Development API URL

    // Function to fetch the current authenticated user
    const fetchCurrentUser = async () => {
      try {
        // Only fetch user data if not already available in localStorage
        if (!user) {
          const response = await axios.get(`${requestUrl}/api/users/current`, { withCredentials: true });
          setUser(response.data); // Set the user data from the response
          localStorage.setItem('authUser', JSON.stringify(response.data)); // Store user in localStorage
        }
      } catch (error) {
        setUser(null);          // If not authenticated, set user to null
        localStorage.removeItem('authUser'); // Remove from localStorage on error
      } finally {
        setLoading(false);     // Authentication status has been determined
      }
    };
  
    fetchCurrentUser(); // Invoke the function on component mount
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {!loading && children} {/* Render children only after loading is complete */}
    </AuthContext.Provider>
  );
};
