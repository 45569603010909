import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import { Heading, Text } from "evergreen-ui";

const PrivacyPolicyPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 768;

  /// Style objects
  const pageLayoutStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    padding: '20px',
    gap: '20px',
    flexWrap: 'wrap',
    alignItems: isMobile ? 'center' : 'flex-start',
  };

  const leftSectionStyle = {
    flex: 1,
    paddingRight: isMobile ? '0' : '20px',
    minWidth: '300px',
    textAlign: isMobile ? 'center' : 'start',
    marginBottom: isMobile ? '20px' : '0',
  };

  const rightSectionStyle = {
    flex: 1,
    paddingLeft: isMobile ? '0' : '20px',
    minWidth: '300px',
  };

  return (
    <div className="container-lg">
      <Header />

      <div className="container-lg my-4" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="hero-container" style={pageLayoutStyle}>
          {/* Left section (image and heading) */}
          <div style={leftSectionStyle}>
            <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', alignItems: 'center' }}>
            </div>

            <div style={{ marginLeft: '0', textAlign: isMobile ? 'center' : 'start' }}>
              <Heading size={1100} textAlign={isMobile ? 'center' : 'start'} fontWeight="light" marginBottom={15}>
                Privacy Policy
              </Heading>

              <Text fontSize={18} textAlign={isMobile ? 'center' : 'start'} color="#333" marginTop={20} marginBottom={15}>
                Protecting your privacy and personal information is our priority.
              </Text>
            </div>
          </div>

          {/* Right section (Privacy Policy content) */}
          <div style={rightSectionStyle}>
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Privacy Policy - Dirt</strong>
            </Text>
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Last updated:</strong> September 10, 2024
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Introduction</strong><br/>
              Dirt Limited (company number [Insert Company Number]) (the Company) is committed to protecting the personal information of all its website and platform users (Users) in accordance with the Privacy Act 2020 (Privacy Act) and the Information Privacy Principles as set out in the Privacy Act.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              This privacy policy helps Users understand how and why the Company collects information, how that information is stored, how they can access and correct it, and when we might disclose information to third parties.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>About the Company and the Services</strong><br/>
              Dirt operates the dirt.co.nz website and associated platforms (together referred to as the Site) and provides a platform (the Platform) that connects Users, including customers (Customers) and service providers such as contractors and material suppliers (Suppliers), for sourcing, supplying, removing, and transporting materials and equipment for construction and land development projects (Services).
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Collection of Personal Information</strong><br/>
              In the course of providing Services, Dirt may collect, store, and use a User's personal information. This may include, but is not limited to:
            </Text>
            <ul>
              <li>Name</li>
              <li>Contact details (including phone number, residential address, email)</li>
              <li>Date of birth</li>
              <li>Gender</li>
              <li>Identification documentation (e.g., driver's license, passport)</li>
              <li>Publicly available information</li>
              <li>Financial information</li>
            </ul>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Purpose and Use of Personal Information</strong><br/>
              The Company collects Personal Information as necessary for its business and activities, which may include but is not limited to the following purposes:
            </Text>
            <ul>
              <li>To generate quotes for Services based on the Personal Information entered into the Platform.</li>
              <li>To connect Customers with Suppliers for material sourcing, transport, and equipment hire.</li>
              <li>To promote and market the Services, including contacting Users electronically.</li>
              <li>To personalize and improve the Services provided to Users.</li>
              <li>To conduct research for improving existing Services or creating new ones.</li>
              <li>To verify User identity, including for AML identity checks.</li>
              <li>To communicate with Users in relation to Services.</li>
              <li>To respond to communications or complaints from Users.</li>
              <li>To protect or enforce our legal rights and interests.</li>
            </ul>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Cookies</strong><br/>
              Dirt uses cookies (small alphanumeric identifiers) to monitor how Users interact with the Site. Users can disable cookies by changing their browser settings, although this may affect their ability to use some features of the Platform and Site.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Protection and Retention of Personal Information</strong><br/>
              Dirt will take all reasonable steps to store personal information securely and protect it from unauthorized access, modification, or disclosure. The Company will hold personal information as long as necessary to fulfill the purpose for which it was collected, but no longer than legally permitted.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Third-Party Links</strong><br/>
              The Site and Platform may contain links to third-party websites. Users acknowledge that Dirt is not responsible for the privacy practices or the content of these third-party websites.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Contact Information</strong><br/>
              For any questions or concerns regarding this privacy policy or to exercise your rights regarding your personal information, please contact Dirt at [Insert Contact Information].
            </Text>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
