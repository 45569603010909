// client/src/views/Auth/LoginPage/LoginPage.jsx

import React, { useState, useContext } from 'react';
import { TextInputField, Button, Checkbox } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import HelpLink from '../../../components/HelpLink';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';

const LoginPage = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext); // Access setUser from AuthContext

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Handle form submission
  const handleLogin = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await axios.post('/api/auth/login', { email, password });

      if (response.status === 200) {
        const userData = response.data.user;
        setUser(userData); // Update AuthContext with authenticated user
        navigate('/listings'); // Redirect to AllListingsPage
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred during login. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container-lg pt-2" style={{ paddingLeft: '2rem', paddingRight: '2rem' }}> 
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 form-wrapper">
          <div className="row">
            <div className="col text-start">
              <h2>Login to Your Materials Account</h2>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-start">
              <p>Enter your credentials to access your account.</p>
            </div>
          </div>
          <form onSubmit={handleLogin}>
            <div className="row mt-4">
              <div className="col text-start">
                <TextInputField
                  label="Email Address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <TextInputField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}  // Toggle password visibility
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col text-start">
                <Checkbox
                  label="Show Password"
                  checked={showPassword}
                  onChange={e => setShowPassword(e.target.checked)}  // Toggle show password state
                />
              </div>
            </div>
            {error && (
              <div className="row mt-2 text-start">
                <div className="col">
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col text-start">
                <HelpLink onClick={() => console.log('Help clicked')} />
              </div>
            </div>
            <div className="row mt-4 justify-content-start">
              <div className="col-12 col-md-4">
                <Button
                  className="btn form-card-button w-100"
                  type="submit"
                  appearance="primary"
                  intent="success"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Logging In...' : 'Login'}
                </Button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <Button
                  className="btn btn-minimal w-100"
                  appearance="minimal"
                  onClick={() => navigate('/signup')}
                >
                  Don't have an account? Sign up here
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
