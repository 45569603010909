import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import { Heading, Text } from "evergreen-ui";

const TermsOfUsePage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /// Style objects
  const pageLayoutStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    justifyContent: 'space-between',
    padding: '20px',
    gap: '20px',
    flexWrap: 'wrap',
    alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
  };

  const leftSectionStyle = {
    flex: 1,
    paddingRight: windowWidth <= 768 ? '0' : '20px',
    minWidth: '300px',
    textAlign: windowWidth <= 768 ? 'center' : 'start',
    marginBottom: windowWidth <= 768 ? '20px' : '0',
  };

  const rightSectionStyle = {
    flex: 1,
    paddingLeft: windowWidth <= 768 ? '0' : '20px',
    minWidth: '300px',
  };

  return (
    <div className="container-lg">
      <Header />

      <div className="container-lg my-4" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="hero-container" style={pageLayoutStyle}>
          {/* Left section (image and heading) */}
          <div style={leftSectionStyle}>
            <div style={{ display: 'flex', justifyContent: windowWidth <= 768 ? 'center' : 'flex-start', alignItems: 'center' }}>
          
            </div>

            <div style={{ marginLeft: '0', textAlign: windowWidth <= 768 ? 'center' : 'start' }}>
              <Heading size={1100} textAlign={windowWidth <= 768 ? 'center' : 'start'} fontWeight="light" marginBottom={15}>
                Terms & Conditions
              </Heading>

              <Text fontSize={18} textAlign={windowWidth <= 768 ? 'center' : 'start'} color="#333" marginTop={20} marginBottom={15}>
                By using Dirt’s platform, you agree to these terms and conditions.
              </Text>
            </div>
          </div>

          {/* Right section (Terms of Use content) */}
          <div style={rightSectionStyle}>
            {/* Clear paragraphs between sections */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Terms of Use - Dirt</strong>
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Last updated:</strong> September 10, 2024
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Introduction</strong><br/>
              Dirt Limited (company number 123456789) (Dirt, we, us) operates the dirt.co.nz website and associated platforms (together referred to as the Site) and provides a platform (the Platform) that connects users, including customers (Customers) and service providers such as contractors and material suppliers (Suppliers), for the sourcing, supply, removal, and transport of materials and equipment for construction and land development projects (Services).
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Binding Terms</strong><br/>
              By accessing or using the Site and Platform, all users (Customers and Suppliers alike) agree to be bound by these Terms of Use (Terms). If you do not agree to these Terms, you must not use the Site or Platform.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Use of the Site and Platform</strong><br/>
              Each User acknowledges and agrees that their use of the Site and Platform is governed by these Terms. Users may only use the Site and Platform in accordance with these Terms. All rights not expressly granted to Users in these Terms are reserved by Dirt.
            </Text>

            {/* About Dirt and Services Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>About Dirt and the Services</strong><br/>
              Dirt provides the following services through the Site and Platform:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li><strong>Material Sourcing:</strong> Enable Customers to request quotes for the supply or removal of materials (e.g., soil, gravel, demolition debris) from various Suppliers.</li>
                <li><strong>Material Transport:</strong> Facilitate transport solutions for the loading, delivery, and removal of materials.</li>
                <li><strong>Equipment Hire:</strong> Enable Customers to hire heavy equipment like loaders, diggers, and other machinery with or without operators.</li>
                <li><strong>Quoting and Contracting:</strong> Allow multiple Suppliers to provide quotes based on specific customer requests, enabling Customers to accept quotes, which, once accepted, become binding contracts (Supply Contract) between the Customer and the Supplier.</li>
                <li><strong>Material Marketplace:</strong> A marketplace for Customers and Suppliers to buy and sell materials.</li>
                <li><strong>Site Assessment and Reporting:</strong> Provide services related to site assessment, including HAIL testing and contamination analysis, through our platform.</li>
                <li><strong>Ancillary Services:</strong> Provide additional related services that complement the above offerings.</li>
              </ul>
            </Text>

            {/* Obligations of Dirt Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Obligations of Dirt</strong><br/>
              While we strive to facilitate accurate and timely transactions, we do not guarantee that the Services, including material supply, transport, or equipment hire, will meet your needs in every instance. We do not directly negotiate terms between Users, nor are we responsible for the execution of contracts or delivery of services arranged between Customers and Suppliers.
            </Text>

            {/* Exclusions Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Exclusions</strong><br/>
              Except as expressly provided in these Terms, Dirt:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Does not endorse, recommend, or guarantee any Supplier, material, or service.</li>
                <li>Is not a party to any contract between a Customer and a Supplier.</li>
                <li>Is not responsible for the safety, legality, or quality of materials supplied or services rendered by Suppliers.</li>
                <li>Does not guarantee that materials or services offered on the Platform will meet any specific requirements or expectations.</li>
              </ul>
            </Text>

            {/* User Responsibilities Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>User Responsibilities</strong><br/>
              By registering for the Site or Platform, Users agree to:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Provide accurate and up-to-date personal information.</li>
                <li>Maintain the confidentiality of their account login details.</li>
                <li>Ensure that their use of the Platform does not violate any local, state, or federal laws.</li>
              </ul>
            </Text>

            {/* Usage Limitations Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Usage Limitations</strong><br/>
              Users must not use the Platform for any illegal activities, including but not limited to:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Posting false or misleading information.</li>
                <li>Engaging in activities that would harm the Site, Platform, or other Users (e.g., introducing viruses, spyware).</li>
                <li>Misrepresenting their identity or affiliations.</li>
                <li>Attempting to circumvent the platform to engage Suppliers or Customers directly for services or materials outside the Platform.</li>
              </ul>
            </Text>

            {/* Good Faith Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Good Faith and No Subversion</strong><br/>
              Users agree to act in good faith when using the Platform and engaging in transactions. Users are prohibited from:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Entering into transactions outside the Platform for services initiated within the Platform.</li>
                <li>Manipulating quotes, bids, or contract terms.</li>
                <li>Directly contacting a Supplier or Customer to circumvent fees or contractual obligations facilitated by the Platform.</li>
              </ul>
            </Text>

            {/* Supplier Obligations Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Supplier Obligations</strong><br/>
              Each Supplier agrees to:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Provide accurate and detailed quotes and service terms.</li>
                <li>Deliver materials or services in accordance with the terms agreed upon with Customers.</li>
                <li>Resolve any disputes arising with Customers regarding materials or services supplied.</li>
                <li>Pay all applicable fees to Dirt for the use of the Platform and related services.</li>
              </ul>
            </Text>

            {/* Customer Obligations Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Customer Obligations</strong><br/>
              Each Customer agrees to:
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <ul>
                <li>Only submit material or service requests with the intention of completing the purchase or rental.</li>
                <li>Comply with all agreed-upon contract terms after accepting a Supplier’s quote.</li>
                <li>Pay all applicable amounts to Suppliers in a timely manner as specified in the contract.</li>
              </ul>
            </Text>

            {/* Fees and Payments Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Fees and Payments</strong><br/>
              Dirt is compensated for providing the Platform and associated services through fees paid by Suppliers, which may be included in Supplier quotes. These fees do not directly affect the price paid by Customers unless explicitly stated in the Supplier’s quote.
            </Text>

            {/* Intellectual Property Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Intellectual Property</strong><br/>
              All intellectual property rights related to the Site, Platform, and any services or systems provided by Dirt are owned by Dirt. Users are prohibited from reproducing, distributing, or using any part of the Platform without explicit written consent from Dirt.
            </Text>

            {/* Liability and Warranties Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Liability and Warranties</strong><br/>
              <strong>Warranties</strong><br/>
              Except as provided by applicable law or as otherwise expressly provided in these Terms, all services, materials, and information provided through the Site and Platform are offered "as-is" without any warranties. Dirt disclaims any warranties, whether express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </Text>

            {/* Liability Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Liability</strong><br/>
              To the extent permitted by law:
              <ul>
                <li>Dirt is not liable for any indirect, incidental, or consequential damages arising out of the use of the Platform or the execution of any contracts facilitated by the Platform.</li>
                <li>Dirt’s total liability to any User for any claim related to the Site, Platform, or services is limited to NZ$500.</li>
              </ul>
            </Text>

            {/* Dispute Resolution Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Dispute Resolution</strong><br/>
              <strong>Disputes with Dirt:</strong> If you have a dispute or issue with the Site, Platform, or Services, please contact us at support@dirt.co.nz and we will try to resolve your concern. If a resolution cannot be reached within 40 days, Users may pursue legal remedies available to them.
            </Text>

            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Disputes Between Customers and Suppliers:</strong> Dirt is not a party to any transaction between a Customer and a Supplier and will not be involved in any disputes between those parties. Customers and Suppliers are encouraged to resolve disputes directly.
            </Text>

            {/* Termination and Suspension Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Termination and Suspension</strong><br/>
              Dirt reserves the right to suspend or terminate a User’s account and access to the Site or Platform at its discretion if these Terms are violated. Upon suspension or termination, Users will be notified and their rights under these Terms will immediately cease.
            </Text>

            {/* Changes to the Terms Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Changes to the Terms</strong><br/>
              Dirt reserves the right to amend these Terms at any time by posting an updated version on the Site. Continued use of the Site or Platform after any amendments constitutes acceptance of the revised Terms.
            </Text>

            {/* Governing Law Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Governing Law</strong><br/>
              These Terms are governed by the laws of New Zealand. Any disputes related to these Terms will be resolved in the courts of New Zealand.
            </Text>

            {/* Contact Information Section */}
            <Text fontSize={16} color="#333" marginBottom={20}>
              <strong>Contact Information</strong><br/>
              For any questions or concerns regarding these Terms or the use of the Platform, please contact us at support@dirt.co.nz.
            </Text>

          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfUsePage;
