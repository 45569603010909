// src/pages/Listings/AllListingsPage.jsx

import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Pane, Text, Heading, Button, SelectMenu, Badge, Icon, toaster } from 'evergreen-ui';
import { DirtFormContext } from "../../../contexts/DirtFormContext";
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import "./index.css";
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useNavigate } from 'react-router-dom'; // For navigation

const dirtBanner = process.env.PUBLIC_URL + '/dirt-banner.svg';
const profileIcon = process.env.PUBLIC_URL + '/dirt-profile.svg';

const breadcrumbPaths = [
    { name: 'Home', url: '/' },
    { name: 'Listings', url: '/listings' },
];

const dateRanges = [
    { label: 'Last 24 Hours', value: '24hrs' },
    { label: 'Last 7 Days', value: '7days' },
    { label: 'Last 2 Weeks', value: '2weeks' },
    { label: 'Last 1 Month', value: '1month' },
    { label: 'Last 6 Months', value: '6months' },
    { label: 'Last 12 Months', value: '12months' },
];

const calculateDateRange = (range) => {
    const now = new Date();
    let startDate;

    switch (range) {
        case '24hrs':
            startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            break;
        case '7days':
            startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            break;
        case '2weeks':
            startDate = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
            break;
        case '1month':
            startDate = new Date(now.setMonth(now.getMonth() - 1));
            break;
        case '6months':
            startDate = new Date(now.setMonth(now.getMonth() - 6));
            break;
        case '12months':
            startDate = new Date(now.setFullYear(now.getFullYear() - 1));
            break;
        default:
            startDate = new Date(0);
    }

    return startDate;
};

export default function AllListingsPage() {
    const { requestUrl } = useContext(DirtFormContext);
    const navigate = useNavigate();  // Initialize navigation hook

    const [listings, setAllListings] = useState([]);
    const [materialFilter, setMaterialFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState("");
    const [equipmentFilter, setEquipmentFilter] = useState([]);
    const [filters, setFilters] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state
    const [error, setError] = useState(''); // Added error state

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const url = `${requestUrl}/api/listings/`; // Corrected to plural
                const response = await axios.get(url, { withCredentials: true });
                setAllListings(response.data);
                console.log('Listings fetched successfully:', response.data);
            } catch (err) {
                console.error('Error fetching listings:', err);
                setError('Failed to load listings. Please try again later.');
                toaster.danger('Failed to load listings. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchListings();
    }, [requestUrl]);

    const getEquipmentRequired = (listing) => {
        if (listing.needsTruck && listing.needsDigger) {
            return "Digger & Truck";
        } else if (listing.needsTruck) {
            return "Truck";
        } else if (listing.needsDigger) {
            return "Digger";
        } else {
            return "N/A";
        }
    };

    const handleTagInputChange = (values) => {
        setFilters(values);

        const newMaterialFilter = values
            .filter(value => value.startsWith('Material: '))
            .map(value => value.replace('Material: ', ''));
        const newDateFilter = values.find(value => value.startsWith('Date: '))?.replace('Date: ', '') || "";
        const newEquipmentFilter = values
            .filter(value => value.startsWith('Equipment: '))
            .map(value => value.replace('Equipment: ', ''));

        setMaterialFilter(newMaterialFilter);
        setDateFilter(newDateFilter);
        setEquipmentFilter(newEquipmentFilter);
    };

    const handleAddFilter = (type, value) => {
        const newFilters = [...filters, `${type}: ${value}`];
        handleTagInputChange(newFilters);
    };

    const handleRemoveFilter = (filter) => {
        const newFilters = filters.filter(f => f !== filter);
        handleTagInputChange(newFilters);
    };

    const clearAllFilters = () => {
        setMaterialFilter([]);
        setDateFilter("");
        setEquipmentFilter([]);
        setFilters([]);
    };

    const handleShareClick = (url) => {
        navigator.clipboard.writeText(url)
            .then(() => {
                toaster.success("Link copied to clipboard!");
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
                toaster.danger("Failed to copy link to clipboard.");
            });
    };

    const filteredListings = listings.filter(listing => {
        const materialMatch = materialFilter.length > 0
            ? materialFilter.some(material => listing.material.toLowerCase().includes(material.toLowerCase()))
            : true;
        const dateMatch = dateFilter
            ? new Date(listing.date) >= calculateDateRange(dateFilter)
            : true;
        const equipmentMatch = equipmentFilter.length > 0
            ? equipmentFilter.every(equipment => getEquipmentRequired(listing).includes(equipment))
            : true;
        return materialMatch && dateMatch && equipmentMatch;
    });

    const handleNewListingClick = () => {
        navigate('/dirt/list');  // Navigate to the new listing page
    };

    if (loading) {
        return (
            <Pane className="container dirt">
                <Pane className="breadcrumb-container">
                    <Breadcrumb paths={breadcrumbPaths} />
                </Pane>
                <Pane className="mt-3">
                    <Text style={{ fontSize: '0.875rem', marginLeft: '0px' }}>Dirt</Text>
                    <Heading size={700}>
                        Material Requests <Badge color="neutral">{listings?.length || 0}</Badge>
                    </Heading>
                    <Text style={{ fontSize: '0.875rem', marginLeft: '0px' }}>Listed materials for review</Text>
                </Pane>
                <Pane className="new-listings-content">
                    <p>Loading listings...</p>
                </Pane>
            </Pane>
        );
    }

    return (
        <Pane className="container dirt">
            <Pane className="breadcrumb-container">
                <Breadcrumb paths={breadcrumbPaths} />
            </Pane>

            {/* New Listings Section */}
            <Pane className="new-listings-container">
                <Pane className="mt-3">
                    <Text style={{ fontSize: '0.875rem', marginLeft: '0px' }}>Dirt</Text>
                    <Heading size={700}>
                        Material Requests <Badge color="neutral">{filteredListings?.length}</Badge>
                    </Heading>
                    <Text style={{ fontSize: '0.875rem', marginLeft: '0px' }}>Listed materials for review</Text>
                </Pane>

                {/* Filters and New Listing Section */}
                <div className="row mb-3">
                    <div className="col-12 d-flex justify-content-between align-items-start">
                        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
                            <SelectMenu
                                title="Filter by Material"
                                options={[
                                    { label: 'Clay', value: 'clay' },
                                    { label: 'Large Rocks', value: 'large-rocks-0-50kg' },
                                    // Add more options as needed
                                ]}
                                selected={[]}
                                onSelect={item => handleAddFilter('Material', item.value)}
                                closeOnSelect={false}
                                multiSelect
                            >
                                <Button style={{ marginBottom: '3px', marginRight: '16px' }}>Filter by Material</Button>
                            </SelectMenu>

                            <SelectMenu
                                title="Filter by Date"
                                options={dateRanges}
                                selected={dateFilter ? [dateFilter] : []}
                                onSelect={item => handleAddFilter('Date', item.value)}
                                closeOnSelect={true}
                            >
                                <Button style={{ marginBottom: '3px', marginRight: '16px' }}>
                                    <Icon icon="calendar" marginRight={8} />
                                    {dateFilter ? dateRanges.find(range => range.value === dateFilter)?.label : "Filter by Date"}
                                </Button>
                            </SelectMenu>

                            <SelectMenu
                                title="Filter by Equipment"
                                options={[
                                    { label: 'Digger', value: 'Digger' },
                                    { label: 'Truck', value: 'Truck' },
                                ]}
                                selected={[]}
                                onSelect={item => handleAddFilter('Equipment', item.value)}
                                closeOnSelect={false}
                                multiSelect
                            >
                                <Button style={{ marginBottom: '3px' }}>Filter by Equipment</Button>
                            </SelectMenu>
                       
                        </div>

                        <Button intent="danger" onClick={clearAllFilters}>Clear All Filters</Button>
                    </div>
            

                        <div className="d-flex align-items-end" style={{ height: '100%' }}>
                            <Button
                                className="new-listing-button"
                                appearance="primary"
                                intent="success"
                                style={{ marginBottom: '0px' }}  // Adds margin below the button
                                onClick={handleNewListingClick}  // Add the click handler for navigation
                            >
                                + New Listing
                            </Button>
                        </div>
                    </div>
               

                {/* Display Applied Filters */}
                <Pane display="flex" flexWrap="wrap" marginBottom={16}>
                    {filters.map((filter, index) => (
                        <Badge
                            key={index}
                            margin={4}
                            color="blue"
                            isInteractive
                            onRemove={() => handleRemoveFilter(filter)}
                        >
                            {filter}
                        </Badge>
                    ))}
                </Pane>

                {/* Display Error Message */}
                {error && (
                    <Pane className="error-message" padding={16} backgroundColor="#FFFAFA" borderRadius={4} marginBottom={16}>
                        <Text color="danger">{error}</Text>
                    </Pane>
                )}

                {/* Listings Content */}
                <Pane className="new-listings-content">
                    {filteredListings.length === 0 ? (
                        <Pane padding={16}>
                            <Text>No listings match the selected filters.</Text>
                        </Pane>
                    ) : (
                        filteredListings.map((listing) => {
                            const requestType = listing?.reqOrList === 'list' ? 'Materials Available' : 'Materials Wanted';

                            return (
                                <Pane className="new-listings-item" key={listing._id}>
                                    <Pane className="listing-container">
                                        <Pane className="listing-section">
                                            <Heading size={600} className="listing-header">
                                                {requestType} {/* Display the dynamic heading */}
                                            </Heading>
                                            <Pane className="listing-image">
                                                {listing.images && listing.images.length > 0 ? (
                                                    <img src={listing.images[0]} alt={`Listing ${listing._id} image 0`} />
                                                ) : (
                                                    <img src={`${process.env.PUBLIC_URL}/placeholder-image.png`} alt="No Image Available" />
                                                )}
                                            </Pane>
                                        </Pane>
                                        <Pane className="listing-material">
                                            <Heading size={400}>Material</Heading>
                                            <Text>{listing.material}</Text>
                                        </Pane>
                                        <Pane className="listing-amount">
                                            <Heading size={400}>Amount</Heading>
                                            <Text>{listing.quantity} {listing.unit}</Text>
                                        </Pane>
                                        <Pane className="listing-date">
                                            <Heading size={400}>Requested Date</Heading>
                                            <Text>{new Date(listing.date).toLocaleDateString()}</Text>
                                        </Pane>
                                        <Pane className="listing-equipment">
                                            <Heading size={400}>Equipment Required</Heading>
                                            <Text>{getEquipmentRequired(listing)}</Text>
                                        </Pane>
                                        <Pane className="listing-details">
                                            <Button
                                                className="hero-button"
                                                is="a"
                                                href={`/listings/${listing._id}`}
                                                style={{
                                                    backgroundColor: '#FFA726', // Orange color
                                                    color: '#FFF',
                                                    fontSize: '12px',
                                                    padding: '10px 20px',
                                                    marginBottom: '16px',
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                View Details
                                            </Button>
                                            <Button
                                                className="share-button"
                                                onClick={() => handleShareClick(`${window.location.origin}/listings/${listing._id}`)}
                                                style={{
                                                    backgroundColor: '#FFF',
                                                    color: '#333',
                                                    fontSize: '12px',
                                                    padding: '10px 20px',
                                                    borderRadius: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    textDecoration: 'none',
                                                    border: '1px solid #E0E0E0',
                                                }}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/link-icon.svg`}
                                                    alt="Share Icon"
                                                    style={{ marginRight: '8px', width: '16px', height: '16px' }}
                                                />
                                                Share
                                            </Button>
                                        </Pane>
                                    </Pane>
                                </Pane>
                            );
                        })
                    )}
                </Pane>
            </Pane>
        </Pane>
    );
}
