import '../index.css';
import { useState, useEffect, useContext } from 'react';
import { Button, TextareaField, RadioGroup } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import HelpLink from '../../../components/HelpLink';
import { DirtFormContext } from '../../../contexts/DirtFormContext';

const BookingDatePage = () => {
  const [date, setDate] = useState('');
  const [notes, setNotes] = useState('');
  const [urgency, setUrgency] = useState('date');
  const navigate = useNavigate();
  const { setFormData } = useContext(DirtFormContext);

  useEffect(() => {
    const defaultDate = '2024-08-01'; // Default date in YYYY-MM-DD format
    setDate(defaultDate);
  }, []);

  const handleSubmit = () => {
    // Save date, notes, and urgency in formData and navigate to ConfirmRequestPage
    setFormData(prevData => ({
      ...prevData,
      date,
      notes,
      urgency
    }));
    navigate('/dirt/confirm'); // Navigating to ConfirmRequestPage
  };

  return (
    <div className="row">
      <div className="col text-center">
        {/* Heading row */}
        <div className="row">
          <div className="col text-start">
            <h2>Choose a date</h2>
          </div>
        </div>
        {/* Subheading row */}
        <div className="row mt-2">
          <div className="col text-start">
            <p>ASAP or schedule a booking?</p>
          </div>
        </div>
        {/* Booking type selection */}
        <div className="row mt-4">
          <div className="col text-start">
            <RadioGroup
              value={urgency}
              options={[
                { label: 'Date', value: 'date' },
                { label: 'ASAP', value: 'asap' }
              ]}
              onChange={event => setUrgency(event.target.value)}
            />
          </div>
        </div>
        {/* Date picker */}
        {urgency === 'date' && (
          <div className="row mt-4">
            <div className="col text-start">
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="form-control custom-date-input"
                placeholder="dd/mm/yyyy"
                style={{ fontSize: '14px' }}
              />
            </div>
          </div>
        )}
        {/* Notes input */}
        <div className="row mt-4">
          <div className="col text-start">
            <TextareaField
              label="Job notes"
              placeholder="Any important details or notes that might help better describe the job request."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              width="100%"
            />
          </div>
        </div>
        {/* Help link */}
        <div className="row mt-4">
          <div className="col text-start">
            <HelpLink onClick={() => console.log('Help clicked')} />
          </div>
        </div>
        {/* Next button row */}
        <div className="row mt-4 justify-content-start">
          <div className="col-12 col-md-4 custom-width-35">
            <Button className="btn btn-warning w-100" onClick={handleSubmit}>
              Next <span className="ml-2"></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDatePage;
