// ConfirmRequestPage.jsx

import '../index.css';
import { Button, Pane, Table } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DirtFormContext } from '../../../contexts/DirtFormContext';
import HelpLink from '../../../components/HelpLink';

const ConfirmRequestPage = () => {
  const navigate = useNavigate();
  const { formData } = useContext(DirtFormContext);

  const handleSubmit = () => {
    // Navigate to signup, carrying form data forward via state
    navigate('/signup', { state: { requestData: formData } });
  };

  return (
    <div className="row">
      <div className="col text-center">
        {/* Heading row */}
        <div className="row">
          <div className="col text-start">
            <h2>Confirm your request</h2>
          </div>
        </div>
        {/* Subheading row */}
        <div className="row mt-2">
          <div className="col text-start">
            <p>Submit your request, we'll be in touch shortly</p>
          </div>
        </div>
        {/* Summary table */}
        <div className="row mt-4">
          <div className="col text-start">
            <Pane>
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Field</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Value</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {/* Displaying each field from formData */}
                  <Table.Row>
                    <Table.TextCell>Request Type</Table.TextCell>
                    <Table.TextCell>
                      {formData.reqOrList === 'list' ? 'I Have Dirt' : 'I Want Dirt'}
                    </Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Address</Table.TextCell>
                    <Table.TextCell>{formData.address}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Material</Table.TextCell>
                    <Table.TextCell>{formData.material}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Unit</Table.TextCell>
                    <Table.TextCell>{formData.unit}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Quantity</Table.TextCell>
                    <Table.TextCell>{formData.quantity}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Needs Truck</Table.TextCell>
                    <Table.TextCell>{formData.needsTruck ? 'Yes' : 'No'}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Needs Digger</Table.TextCell>
                    <Table.TextCell>{formData.needsDigger ? 'Yes' : 'No'}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Date</Table.TextCell>
                    <Table.TextCell>{formData.date}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Notes</Table.TextCell>
                    <Table.TextCell>{formData.notes}</Table.TextCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.TextCell>Images</Table.TextCell>
                    <Table.TextCell>
                      {formData.images && formData.images.length > 0 ? (
                        formData.images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`thumbnail-${index}`}
                            style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
                          />
                        ))
                      ) : (
                        <span>No images uploaded.</span>
                      )}
                    </Table.TextCell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Pane>
          </div>
        </div>
        {/* Help link */}
        <div className="row mt-4">
          <div className="col text-start">
            <HelpLink onClick={() => console.log('Help clicked')} />
          </div>
        </div>
        {/* Submit button row */}
        <div className="row mt-4 justify-content-start">
          <div className="col-12 col-md-4 custom-width-35">
            <Button className="btn btn-warning w-100" onClick={handleSubmit}>
              Proceed to Signup <span className="ml-2"></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRequestPage;
