// src/contexts/DirtFormContext.js

import React, { createContext, useState } from 'react';

export const DirtFormContext = createContext();

var environment = process.env.NODE_ENV || 'development'
var requestUrl = null

if (environment !== "production") {
    requestUrl = "http://localhost:4000"
} else {
    var requestUrl = "https://www.dirt.nz"
}

export const DirtFormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    reqOrList: '',
    material: '',
    unit: 'cubic',
    quantity: '',
    needsTruck: false,
    trucks: [],
    needsDigger: false,
    diggers: [],
    address: '',
    date: '',
    urgency: '',
    notes: '',
    images: [],
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    businessName: '',
  });

  return (
    <DirtFormContext.Provider value={{ formData, setFormData, requestUrl }}>
      {children}
    </DirtFormContext.Provider>
  );
};
