import React from 'react';
import { Pane, Heading, Button, Text } from 'evergreen-ui';
import styled from 'styled-components';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { DirtFormContext } from 'contexts/DirtFormContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)`
  background-color: #FFFFFF;
  border: 1px solid white;
  &:hover {
    background-color: #FFFFFF !important;
    color: #ffffff !important;
    border: 1px solid #FFFFFF !important;
  }
  &:focus {
    background-color: #FFFFFF !important;
    color: #00022F !important;
    border: 1px solid #FFFFFF !important;
  }
`;

const StyledNav = styled.nav`
  background-color: #FFFFFF !important;
`;

const Header = () => {
  const { user, setUser, loading } = useContext(AuthContext); // Access user and loading state from AuthContext
  const { requestUrl } = useContext(DirtFormContext);
  const navigate = useNavigate();

  const logoutUser = (evt) => {
    axios({
      url: `${requestUrl}/api/users/logout`,
      method: 'POST',
      withCredentials: true
    })
      .then((res) => {
        localStorage.removeItem('authUser');
        setUser(null);
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewListings = () => {
    if (!user) {
      navigate('/login'); // Redirect to login if not logged in
    } else {
      navigate('/listings'); // Redirect to listings if logged in
    }
  };

  return (
    <StyledNav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="/dirt-logo-wheelbarrow(3).svg" alt="Dirt Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mt-2" id="navbarNav">
          <ul className="navbar-nav" style={{ marginLeft: 'auto' }}>
            <li className="nav-item">
              <a className="nav-link" href="/about">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">Contact</a>
            </li>
            <li className="nav-item">
              <p
                className="nav-link"
                style={{ cursor: 'pointer' }}
                onClick={handleViewListings}
              >
                View Listings
              </p>
            </li>
            {!user ? (
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>
              </li>
            ) : (
              <li className="nav-item">
                <p className="pt-2" style={{ cursor: 'pointer' }} onClick={logoutUser}>
                  Logout
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </StyledNav>
  );
};

export default Header;
