import React from "react";
import { Pane, Button, Heading } from "evergreen-ui";
import { useNavigate } from "react-router-dom";

const LearnMoreSectionSiteAssess = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/dirt/list');
  };

  const handleContactUs = () => {
    navigate('/about');
  };

  return (
    <Pane className="learn-more-section pt-4" textAlign="center">
      <Heading 
        size={600} 
        marginBottom=".5rem" 
        fontWeight={500} 
        lineHeight={1.2} 
        color="#0f173f"
      >
        Plan and assess site materials accurately. Optimise resources and reduce waste.
      </Heading>
      <Pane 
        className="button-group" 
        display="flex" 
        justifyContent="center" 
        gap={16} 
        marginTop={20}
      >
        <Button
          appearance="primary"
          backgroundColor="#FFA236"
          color="#FFFFFF"
          paddingY={20}
          paddingX={60}
          borderRadius={8}
          fontSize={11}
          fontWeight={300}
          border="none"
          onMouseOver={(e) => e.target.style.backgroundColor = '#FF8A00'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#FFA236'}
          onClick={handleGetStarted}
        >
          Get started
        </Button>
        <Button
          appearance="default"
          backgroundColor="#FFA236"
          color="#FFFFFF"
          paddingY={20}
          paddingX={60}
          borderRadius={8}
          fontSize={11}
          fontWeight={300}
          border="none"
          onMouseOver={(e) => e.target.style.backgroundColor = '#FF8A00'}
          onMouseOut={(e) => e.target.style.backgroundColor = '#FFA236'}
          onClick={handleContactUs}
        >
          Contact us
        </Button>
      </Pane>
    </Pane>
  );
};

export default LearnMoreSectionSiteAssess;
