import React from 'react';
import { Pane, Heading, Text } from 'evergreen-ui';

const Footer = () => {
  return (
    <div className="footer-container container py-4">
      <div className="footer-row">

        {/* Footer Section 1 - Company */}
        <div className="col-12 col-md-3 my-2">
          <Pane>
            <Heading size={600} color="#000" marginBottom={16}>Company</Heading>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li><a href="/about" style={{ textDecoration: 'none', color: '#000' }}>About</a></li>
            </ul>
          </Pane>
        </div>

        {/* Footer Section 2 - Help */}
        <div className="col-12 col-md-3 my-2">
          <Pane>
            <Heading size={600} color="#000" marginBottom={16}>Help</Heading>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li><a href="/about" style={{ textDecoration: 'none', color: '#000' }}>Customer Support</a></li>
              <li><a href="/terms-of-use" style={{ textDecoration: 'none', color: '#000' }}>Terms & Conditions</a></li>
              <li><a href="/privacy-policy" style={{ textDecoration: 'none', color: '#000' }}>Privacy Policy</a></li>
            </ul>
          </Pane>
        </div>

        {/* Footer Section 3 - Services */}
        <div className="col-12 col-md-3 my-2">
          <Pane>
            <Heading size={600} color="#000" marginBottom={16}>Services</Heading>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li><a href="/materials" style={{ textDecoration: 'none', color: '#000' }}>Material Supply</a></li>
              <li><a href="/loading-and-transport" style={{ textDecoration: 'none', color: '#000' }}>Loading and Transport</a></li>
              <li><a href="/demolition" style={{ textDecoration: 'none', color: '#000' }}>Demolition</a></li>
              <li><a href="/recycling" style={{ textDecoration: 'none', color: '#000' }}>Recycling</a></li>
              <li><a href="/hail-testing" style={{ textDecoration: 'none', color: '#000' }}>Hail Testing</a></li>
            </ul>
          </Pane>
        </div>

        {/* Footer Section 4 - Contact Information */}
        <div className="col-12 col-md-3 my-2">
          <Pane>
            <Heading size={600} color="#000" marginBottom={16}>Contact Information</Heading>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <Text size={500} color="#000">hello@dirt.nz</Text>
              </li>
              <li>
                <Text size={500} color="#000">
                  <strong>AKL</strong> - <a href="tel:+6421455564" style={{ color: 'inherit', textDecoration: 'none' }}>+64 21455564</a>
                </Text>
              </li>
              <li>
                <Text size={500} color="#000">
                  <strong>DND</strong> - <a href="tel:+6421440042" style={{ color: 'inherit', textDecoration: 'none' }}>+64 21440042</a>
                </Text>
              </li>
            </ul>
          </Pane>
        </div>
      </div>

      {/* Footer Bottom - Logo and Copyright */}
      <div className="footer-bottom d-flex justify-content-between align-items-center pt-4">
        <img 
          src="/dirt-logo-wheelbarrow(3).svg" 
          alt="Dirt Logo" 
          width={170} 
          height={90} 
        />
        <Text color="#000">© Copyright 2024, All Rights Reserved by Dirt</Text>

        {/* Social Media Icons */}
        <div className="social-icons">
          <a href="https://twitter.com" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
          <a href="https://facebook.com" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
          <a href="https://instagram.com" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
          <a href="https://github.com" aria-label="GitHub"><i className="fab fa-github"></i></a>
        </div>
      </div>

      <style jsx>{`
        .footer-container {
          background-color: #FFFFFF; /* Set background to white */
          color: #000; /* Set text color to black */
          padding: 40px 20px;
          margin-top: 40px; /* Add a top margin to create a gap from the main content */
        }

        .footer-row {
          display: flex;
          flex-wrap: wrap;
        }

        .footer-row > div {
          flex: 1;
          margin-right: 20px;
        }

        .footer-bottom {
          border-top: 1px solid #e0e0e0;
          padding-top: 20px;
          margin-top: 20px;
        }

        .social-icons a {
          color: #000;
          margin-right: 15px;
          font-size: 20px;
        }

        ul {
          list-style-type: none;
          padding-left: 0;
        }

        ul li a {
          color: #000;
          text-decoration: none;
        }

        @media (max-width: 768px) {
          .footer-row {
            flex-direction: column;
            text-align: center;
          }

          .footer-bottom {
            flex-direction: column;
            align-items: center;
          }

          .social-icons {
            margin-top: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default Footer;
