// client/src/contexts/AppContext.jsx

import React, { createContext } from 'react';

// Create the AppContext
export const AppContext = createContext();

/**
 * AppProvider Component
 * 
 * This component provides application-wide settings and variables to its children.
 */
export const AppProvider = ({ children }) => {
  // Determine the request URL based on the environment
  const requestUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://dirt.nz' // Replace with your actual production URL
      : 'http://localhost:4000';

  return (
    <AppContext.Provider value={{ requestUrl }}>
      {children}
    </AppContext.Provider>
  );
};
