import '../index.css';
import { useState, useContext, useEffect } from 'react';
import { FileUploader, Button, Heading, Pane, IconButton, Text, Spinner, Alert } from 'evergreen-ui';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import HelpLink from '../../../components/HelpLink';
import { DirtFormContext } from '../../../contexts/DirtFormContext';
import axios from 'axios';

const UploadImagesPage = () => {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { formData, setFormData, requestUrl } = useContext(DirtFormContext);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false); // Success state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  useEffect(() => {
    if (formData.images && formData.images.length > 0) {
      const initializedFiles = formData.images.map(url => ({
        name: url.split('/').pop(),
        size: 0,
        url: url
      }));
      setFiles(initializedFiles);
    }
  }, [formData.images]);

  const handleUpload = (newFiles) => {
    if (files.length + newFiles.length <= 20) {
      setFiles([...files, ...newFiles]);
    } else {
      alert("You can only upload up to 20 images.");
    }
  };

  const handleRemove = (file) => {
    setFiles(files.filter(f => f !== file));
  };

  const handleSubmit = async () => {
    setUploading(true);
    setUploadSuccess(false);
    setErrorMessage(""); // Reset error message before each upload

    const uploadData = new FormData();
    files.forEach(file => {
      if (file.url) {
        uploadData.append('images', file.url);
      } else {
        uploadData.append('images', file);
      }
    });

    const url = `${requestUrl}/upload`;

    axios({
      url: url,
      method: 'POST',
      data: uploadData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      // If upload is successful
      if (res.status === 200 && res.data.imageUrls) {
        const images = res.data.imageUrls;
        setFormData(prevData => ({ ...prevData, images: images }));
        setUploading(false);
        setUploadSuccess(true); // Set success state
      } else {
        setErrorMessage("Unexpected response from server.");
        setUploading(false);
      }
    })
    .catch((err) => {
      // If upload fails
      setUploading(false);
      setErrorMessage("Upload failed. Please try again."); // Set error message
      console.error(err);
    });
  };

  const handleNext = () => {
    if (uploadSuccess) {
      navigate('/dirt/date'); // Navigate to the next page only when upload is successful
    }
  };

  const handleSkip = () => {
    navigate('/dirt/date');
  };

  return (
    <div className="row">
      <div className="col text-center">
        <div className="row">
          <div className="col text-start">
            <h2>Add Images or Plans</h2>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-start">
            <p>Upload site or material images/plans, including access points. Leave blank if none.</p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <FileUploader
              label="Upload Files"
              description="You can upload up to 20 files. Accepted formats include .jpg, .png, .pdf, .gif, and .heif."
              onAccepted={handleUpload}
              maxFiles={20 - files.length} // Allow up to 20 files
              maxSizeInBytes={9 * 1024 * 1024} // 9MB file size limit
              acceptedMimeTypes={['image/jpeg', 'image/png', 'application/pdf', 'image/gif', 'image/heif']} // Accepted formats
              renderFile={(file) => (
                <Pane key={file.name} display="flex" alignItems="center" marginBottom={8}>
                  <img
                    src={file.url || URL.createObjectURL(file)}
                    alt={`preview-${file.name}`}
                    onError={(e) => e.target.src = `${process.env.PUBLIC_URL}/image-uploaded.svg`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '16px' }}
                  />
                  <Pane flex={1}>
                    <Heading size={400}>{file.name}</Heading>
                    <Text>{file.size ? (file.size / 1024).toFixed(2) : 'N/A'} KB</Text>
                  </Pane>
                  <IconButton icon={CloseIcon} onClick={() => handleRemove(file)} />
                </Pane>
              )}
              values={files}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-start">
            <HelpLink onClick={() => console.log('Help clicked')} />
          </div>
        </div>

        {/* Success Message */}
        {uploadSuccess && (
          <div className="row mt-2">
            <div className="col">
              <Alert intent="success" title="Upload successful!" /> {/* Success message displayed */}
            </div>
          </div>
        )}

        {/* Error Message */}
        {errorMessage && (
          <div className="row mt-2">
            <div className="col">
              <Alert intent="danger" title={errorMessage} /> {/* Error message displayed */}
            </div>
          </div>
        )}
{/* Buttons section */}
<div className="row mt-4 justify-content-start">
  {/* Skip button */}
  <div className="col-12 col-md-6 mb-3 d-flex align-items-start" style={{ flexWrap: "wrap" }}>
    <Button
      className="btn btn-outline-warning"
      style={{
        marginTop: "10px",
        padding: "0.5rem 3rem",
        fontSize: "0.75rem",
        marginRight: "10px",
        width: "auto",
        maxWidth: "200px",
        borderColor: "#FFBD00",
        color: "#FFBD00",
        backgroundColor: "transparent"
      }}
      onClick={handleSkip}
    >
      Skip
    </Button>
    <Button
  className="btn"
  style={{
    padding: "0.5rem 7.35rem",
    fontSize: "0.75rem",
    width: "auto",
    maxWidth: "200px",
    backgroundColor: uploadSuccess ? 'green' : '#FFC107', // Button changes to green on success
    color: 'white',
    marginTop: "10px", // Adds space between Skip and Upload buttons on small screens
  }}
  onClick={uploadSuccess ? handleNext : handleSubmit} // Next only when uploadSuccess is true
  disabled={uploading} // Disable the button while uploading
>
  {/* Show the spinner while uploading */}
  {uploading ? (
    <>
      <Spinner size={16} /> Uploading...
    </>
  ) : (
    uploadSuccess ? 'Next' : 'Upload'
  )}
</Button>
  </div>
</div>

      </div>
    </div>
  );
};

export default UploadImagesPage;
