// src/views/DirtForm/SelectionPage/index.jsx
import '../index.css';
import { useContext } from 'react';
import { Pane } from 'evergreen-ui';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { useNavigate } from 'react-router-dom';
import { DirtFormContext } from '../../../contexts/DirtFormContext';

export default function SelectionPage() {
    const navigate = useNavigate();
    const { setFormData } = useContext(DirtFormContext);

    const handleClick = (evt, type) => {
        setFormData(prevData => ({ ...prevData, reqOrList: type }));
        navigate('/dirt/address');
    };

    return (
        <div className="container-lg selection-page-container"> {/* Changed container to container-lg */}
            <div className="text-center">
                {/* Heading row */}
                <div className="row">
                    <div className="col text-start">
                        <h2>Welcome to Dirt!</h2>
                    </div>
                </div>

                {/* Subheading row */}
                <div className="row mt-2">
                    <div className="col text-start">
                        <p>Let us know if you have materials to supply or need materials</p>
                    </div>
                </div>

                {/* Button row */}
                <div className="row justify-content-start mt-4">
                    {/* Button 1 */}
                    <div className="col-12 col-md-6 mb-3">
                        <button className="form-card-button" onClick={(evt) => handleClick(evt, 'list')}>
                            <div className="col py-3 px-3 mt-5">
                                <div className="row">
                                    <div className="col text-start">  
                                        <h4>I Have Materials</h4> 
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col text-start">
                                        <ArrowCircleRightRoundedIcon fontSize="large"/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                    {/* Button 2 */}
                    <div className="col-12 col-md-6 mb-3">
                        <button className="form-card-button" onClick={(evt) => handleClick(evt, 'request')}>
                            <div className="col py-3 px-3 mt-5">
                                <div className="row">
                                    <div className="col text-start">  
                                        <h4>I Want Materials</h4> 
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col text-start">
                                        <ArrowCircleRightRoundedIcon fontSize="large" sx={{ color: '#ffc727' }} />
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
