// client/src/components/ProtectedRoute.jsx

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; // Adjust the path if AuthContext is located elsewhere

/**
 * ProtectedRoute Component
 * 
 * This component wraps around other components/routes to protect them from unauthenticated access.
 * If the user is not authenticated, they are redirected to the login page.
 * Otherwise, the wrapped component is rendered.
 * 
 * @param {React.ReactNode} children - The component(s) to render if authenticated.
 * @returns {React.ReactNode} - Either the children components or a Navigate component to redirect.
 */
const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext); // Access user and loading state from AuthContext

  // While authentication status is being determined, you can return a loader or null
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <p>Loading...</p>
      </div>
    );
  }

  // If user is not authenticated, redirect to login
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  // If user is authenticated, render the children components
  return children;
};

export default ProtectedRoute;
