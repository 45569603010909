import React from 'react';
import { Text, Pane } from 'evergreen-ui';

const HelpLink = ({ onClick }) => {
  return (
    <Pane display="flex" alignItems="center">
      <Text cursor="pointer" onClick={onClick}>Need help?</Text>
    </Pane>
  );
};

export default HelpLink;
