// src/components/YellowDigger.js
import React from 'react';

const YellowDigger = () => (
  <svg width="787" height="429" viewBox="0 0 787 429" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="787" height="429" fill="url(#pattern0_260_18698)" />
    <defs>
      <pattern id="pattern0_260_18698" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_260_18698" transform="scale(0.00127065 0.002331)" />
      </pattern>
      <image id="image0_260_18698" width="787" height="429" xlinkHref={
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAxMAAAGtCAYAAABtBSl8AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAAB" +
        "AAEAAKACAAQAAAABAAADE6ADAAQAAAABAAABrQAAAACuQugUAABAAElEQVR4AexdB3gc1dU9qy73bjDGFYPBNqYb03sJJaFDgNB7dYBA6BBKaDEQencBUxL4E0ro2IDpONRQjDG9uMtNX" +
        "fuf82ZntLvaJlkrraT7/I1mdua+dna9e+67974bghVDwBAwBAyBeAQKenbCubVhrBv/IBuv68IILa/A3Wx7ejbatzbbFQIhzuYEHhu3q1klnsw83r6cR2Xix3bXEDAEcgEBfSlZMQQMA" +
        "UPAEIhFYJ3eXTDrrwejU+zt7Lz6dgHw9+fwTVk5hmanB2u1HSHQvSgf8249CkXtaE4Jp3LfdJS/+ZVTnKYkFLCbhoAhkBMIFOTEKGwQhoAhYAjkGALFhag5dvuWGdT7c4Fbn0dNy/Rmv" +
        "bR1BPLyEG6pz2ZrYjVnHoqpTAxszTFY34aAIZAegbz0IiZhCBgChoAhYAgYAoZAyyKwZi/kdS7B8Jbt1XozBAyBxiJglonGImbyhoAhYAi0PgLdu5bg02UVWKP1h2IjMASyg8DAXkBJA" +
        "YavyE7z1qohYAg0EwKmTDQTkNaMIWAIGAItiECPkkL0WHpvC/ZoXeUEAmUrgcFn5MRQsj4IKRO1debmlHWgrQNDYBURMGViFQG06oaAIWAIGAKGgCEQi8DnPwLzlgELeKzkXkwrmrAf0" +
        "7Jy1qtwyoR2r2qP5VVO6rP2ODGbU8dCwJSJjvV+22wNAUPAEDAEDIGsIHD/DOCJ94CZXwI7jQJ+KQP6dAVGMYR63tKmdXn09ihhzTuaVju3az3/EZbNnY+xHCW3YLBiCLRdBEyZaLvvn" +
        "Y3cEDAEDAFDwBBodQT++m/g0seBk3cCjuMOaA/QjtCrS6sPK+cH8NIn6HrobXj81zJsmPODtQEaAikQsN2cUoBjjwwBQ8AQMAQMAUMgMQIffgsMYfxGr85AGVMu/u0wYK+NTJFIjFbDu" +
        "zuOBjYdhhFFRTiw4VO7Ywi0HQRMmWg775WN1BAwBAwBQ8AQyAkE3vsauO5pYMZFwPE7AszLYqUJCNx/AjrnA/c1oapVMQRyBgFTJnLmrbCBGAKGgCFgCBgCuY/AouV0Z7oHmHoyd5bqk" +
        "/vjzeURKqbk3D1RxPMNuTxOG5shkAoBi5lIhY49MwQMAUPAEDAEDIEYBPqdBFROirmV9MXDbwCzvuGOTlUAc6Ng46HA/uOSinfIB5fsh8KbngVRxfU8fu6QINik2zQCZplo02+fDd4QM" +
        "AQMAUPAEGg5BM6aCjx+JpCfhj18/hOw7jnAdG58OnYwsDdjKUavCbzwCbDJhcB3C1puzG2hpwdOROlq3fBoWxirjdEQiEfALBPxiNhrQ8AQMAQMAUPAEGiAgNybHngNuIGB1qnKDwuBY" +
        "++iIkGloX/3WMlDtwR+XAQceLOnlPTvEfu8o77ae2Ng4n+w4fyl2L0W+E9HxcHm3TYRSLO20DYnZaM2BAwBQ8AQMAQMgeZF4NG3gLN/k7rNBUuBExlO/PqlDRUJv+YavZiLgs8Pux2oo" +
        "PuTFQ8BBWN36QTafqwYAm0LAVMm2tb7ZaM1BAwBQ8AQMARaBYF3uYNTvKUhfiB/45r64VvF3038WlaKa59K/Kwj3h3S1+Xp6NKnCy7riPO3ObddBEyZaLvvnY3cEDAEDAFDwBBoMQRq6" +
        "H+z9mqpu3v7K2CPDVLL+E93Wx94i/JW6hG47vcoWlGJP/NOt/q7dmUI5DYCpkzk9vtjozMEDAFDwBAwBHICgRV0SfqVbkzJysJl3vMupckkYu+vxniJL7h3kbk6xeJyz3EoXL07Hom9a" +
        "68MgdxFwJSJ3H1vbGSGgCFgCBgChkDOILDuABL/6uTDKWHium/mJ3+e6MnX84CSokRPOu6939P9a0Av8C+27rgo2MzbEgK2m1NberdsrIaAIWAINCMCr/wPePXzxA2OXwvYhW4oKv/7A" +
        "Xjyv951qr9r9GRQbcRfflk5cNuLqaTrn/1pTyAU8l7fwTplrJuuHDy+PmHaMx8AH3+frgaw1drAlut4ch99xy1zPkxfZ1Bv4JAtPLmlK4HbX0pc55jtACUgU5n6OvBtmq1PD9ocWCuNy" +
        "5DXWu783Wakl/VasQ6JSmfmkVhvoKdQyP8/XfmU79m2bNNKQwQmnYiu21yOaYtWgIhaMQRyGwFTJnL7/bHRGQKGgCGQFQSqaoCdrkJdXRh/SdQByf35y+9FYadiyl2N5T8vwYOUW5JI1" +
        "r/XuRj79u2GEbtSCTntAayc9Dqm89nH/vMk582WrcTWVxyEgodmAn+cio/Kq9Nujbn6lNfw20+uRXdtQ3rATVjCpGh3Jmk/uM3cCGdVT0aBFJcdr8CKBSswiQ/pnJO8cE4HDuiJoduu6" +
        "3YpWjHtTTcnZkuIKWu9+Al2euHP6P76F07uO/q9T4mRiH2x2v0zcMBXE9uWX/zOY4C//psJ6CoBfS4SlYPGAf98Bzhrj0RPY+89/h5wEJVCKw0RGEUVYv/N0OvhtzBhaTkmNpSwO4ZA7" +
        "iBgykTuvBc2EkPAEDAEWgwBBdOSXNfW1eLSRJ0W5eNPVDicMlFUgDrK/J3Hp4lk/XulhRhHN5gRel1cBLnRPsPjVr1OUc6iL75b61YiNK5u/0hl4rwU8nq0SU0Yv9VF"
      } />
    </defs>
  </svg>
);

export default YellowDigger;
