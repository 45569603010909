import React from 'react';
import { Outlet } from 'react-router-dom';
import { Pane, Heading, Button, Text, Link, IconButton } from 'evergreen-ui';
import styled from 'styled-components'
import Footer from '../../components/Footer';
import Header from '../../components/header';
import './index.css'
import { ArrowLeftIcon } from 'evergreen-ui';



const DirtForm = () => {
  return (
    <div>
      <Header />
            <section>
                  <div className="form">
                      {/* Container */}
                      <div className="container-md">
                          <div className="row pt-5 mx-5">
                              <div className="col py-5 px-5 button-background">
                                      {/* Mobile header/shopping cart icon */}
                                      <div className="col px-2 py-2 d-block d-md-none">
                                          <img src="/dirt-wheelbarrow-logo.svg" width={50} height={50} alt="Wheelbarrow Logo" />
                                      </div>

                                      {/* Content inserted below */}
                                      <Outlet />


                                      {/* Back button */}
                                      <div className="row mt-5">
                                          <div className="col text-start">   
                                              <IconButton style={{borderRadius: '100rem'}} icon={ArrowLeftIcon} onClick={(evt) => window.history.back()}/>

                                          </div>
                                      </div>
                              </div>
                          </div>
                        </div>
                  </div>
            </section>
      <Footer />
    </div>
  );
}

export default DirtForm;
