// axiosInstance.js

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'http://localhost:4000', // Adjust based on your backend's URL
  withCredentials: true, // Enable sending cookies with requests
});

export default axiosInstance;
