import React, { useEffect, useState } from 'react';
import { DirtFormContext } from '../../../contexts/DirtFormContext';
import { useParams, useLocation } from 'react-router-dom';
import { useContext } from "react";
import axios from 'axios';
import { Pane, Heading, Button, Text, Dialog, Paragraph } from 'evergreen-ui';
import styled from 'styled-components';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import "../AllListingsPage/index.css";

const StyledButton = styled(Button)`
  background-color: #FFA236;
  border: 1px solid white;
  color: white;
  width: 100%;
  border-radius: 8px; /* Added border-radius */
  &:hover {
    color: #FFA236 !important;
    background-color: white !important;
    border: 1px solid #FFA236 !important;
  }
  &:focus {
    color: #FFA236 !important;
    background-color: white !important;
    border: 1px solid #FFA236 !important;
  }
`;


const profileIcon = process.env.PUBLIC_URL + '/dirt-profile.svg';

const ImageGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2rem;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ThumbnailImage = styled.img`
  cursor: pointer;
  border: ${props => props.isSelected ? '2px solid grey' : 'none'};
  border-radius: 0.5rem;
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

const MainImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 600px;
  border-radius: 8px;
`;

const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  return (
    <ImageGalleryContainer>
      <MainImage src={selectedImage} alt="" />
      <ThumbnailContainer>
        {images.map((image, index) => (
          <ThumbnailImage
            key={index}
            src={image}
            isSelected={image === selectedImage}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </ThumbnailContainer>
    </ImageGalleryContainer>
  );
};

export default function ListingPage() {
  const { listingId } = useParams();
  const location = useLocation();
  const [listing, setListing] = useState();
  const { requestUrl } = useContext(DirtFormContext);
  const [isContactDialogShown, setIsContactDialogShown] = useState(false);

  useEffect(() => {
    const url = `${requestUrl}/api/listings/${listingId}`;
    axios({
      url: url,
      method: 'GET'
    })
    .then((res) => {
      setListing(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [listingId]);

  const breadcrumbPaths = [
    { name: 'Home', url: '/' },
    { name: 'Listings', url: '/listings' },
    { name: 'Summary', url: `/listings/${listingId}` }
  ];

  const materialType = location.state?.material || listing?.material;
  const unit = location.state?.unit || listing?.unit || 'cubic';
  const quantity = location.state?.quantity || listing?.quantity;
  const requestType = listing?.reqOrList === 'list' ? 'I Have Dirt' : 'I Want Dirt';
  const notes = listing?.notes;

  const handleContactClick = () => {
    setIsContactDialogShown(true);
  };

  console.log(listing)

  // Function to handle email inquiry button
  const handleEmailClick = () => {
    window.location.href = "mailto:jack@dirt.nz?subject=Inquiry about the Listing&body=Hi Jack,";
  };

  return (
    <div className="dirt">
      <Breadcrumb paths={breadcrumbPaths} />
      <div className="container">
        {listing && (
          <>
            <Text size={500} marginBottom="5px">Dirt</Text>
            <Heading size={800} marginBottom="5px">
              {quantity}{unit === 'cubic' ? 'm³' : 'tons'} of {materialType}
            </Heading>
            <Text size={500} marginTop="5px" marginBottom="10px">Material Summary</Text>
            <Pane height={20} />  {/* Adds spacing */}
            <ImageGallery images={listing.images} />
          </>
        )}
        <div className="row">
          <div className="col-md-8 mb-4">
            <Pane>
              <Heading size={700} textAlign="left">Specifications</Heading>
              <Pane marginTop={12}>
                <Pane display="flex" gap={8} flexWrap="wrap">
                  {listing?.needsTruck && (
                    <Pane 
                      display="flex" 
                      alignItems="center" 
                      justifyContent="center" 
                      padding={8} 
                      background="greenTint" 
                      borderRadius={4}
                    >
                      <Text color="green" size={400}>
                        NEEDS TRUCK
                      </Text>
                    </Pane>
                  )}
                  {listing?.needsDigger && (
                    <Pane 
                      display="flex" 
                      alignItems="center" 
                      justifyContent="center" 
                      padding={8} 
                      background="greenTint" 
                      borderRadius={4}
                    >
                      <Text color="green" size={400}>
                        NEEDS DIGGER
                      </Text>
                    </Pane>
                  )}
                </Pane>
                <Pane marginTop={24}>
                  <Pane>
                    <Heading size={600}>Listing Details</Heading>
                    <Pane height={20} />
                  </Pane>
                  <Pane display="flex" justifyContent="space-between">
                      <Text size={500} fontWeight={550}>Request Type:</Text>
                      <Text size={500}>{requestType}</Text>
                  </Pane>
                  <Pane display="flex" flexDirection="column" gap={16} marginTop={16}>
                    <Pane display="flex" justifyContent="space-between">
                      <Text size={500} fontWeight={550}>Material Type:</Text>
                      <Text size={500}>{listing?.material}</Text>
                    </Pane>
                    <Pane display="flex" justifyContent="space-between">
                      <Text size={500} fontWeight={550}>Quantity:</Text>
                      <Text size={500}>{listing?.quantity} {unit === 'cubic' ? 'm³' : 'tons'}</Text>
                    </Pane>
                    <Pane display="flex" justifyContent="space-between">
                      <Text size={500} fontWeight={550}>Requested Date:</Text>
                      <Text size={500}>
                        {new Date(listing?.date).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </Text>
                      </Pane>
<Pane>
  <Text size={500} fontWeight={550}>Address:</Text>
  <Text size={500}marginLeft={9}>{listing?.address}</Text>
</Pane>

{notes && (
  <Pane>
    <Text size={500} fontWeight={550}>Notes:</Text>
    <Text size={500}marginLeft={9}>{notes}</Text>
  </Pane>
)}

</Pane>
</Pane>
</Pane>
</Pane>
</div>

<div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', minHeight: '100%' }}>
  <Pane 
    background="white" 
    padding={20} 
    borderRadius={8} 
    boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)" 
    textAlign="center"
  >
    <img src={`${process.env.PUBLIC_URL}/profile-wheelbarrow.svg`} alt="Phone Icon" style={{ width: '60px', height: '60px', marginBottom: '10px', marginTop: "15px" }} />
    <Text size={600} display="block" marginTop="10px" marginBottom="50px">
    Contact for Materials</Text>
    <Text size={400}>{listing?.businessName}</Text>
    <Pane marginTop={16}>
      <StyledButton size={400} width="80%" height="40px" onClick={handleContactClick}>
        Call Us
      </StyledButton>
    </Pane>
    <Pane marginTop={16}>
      <StyledButton width="80%" height="40px" onClick={handleEmailClick}>
        Email Us 
      </StyledButton>
    </Pane>
  </Pane>
</div>
        </div>
      </div>

      {/* Contact Dialog */}
      <Dialog
        isShown={isContactDialogShown}
        title="Contact Details"
        onCloseComplete={() => setIsContactDialogShown(false)}
        hasFooter={false}
      >
        <Pane>
        <Paragraph>
  <strong>Contact us for material needs, assessments, or inquiries:</strong>
</Paragraph>
<Heading size={800} marginBottom="20px" fontWeight={200}>
  {listing?.offerId}
</Heading>
<Paragraph>
  <strong>Call and ask for a Dirt-Bag Team Member:</strong>
</Paragraph>

          <Heading size={600} marginTop="40px" marginBottom="20px" fontWeight={550}>
            <a href="tel:+6421455564" style={{ textDecoration: 'underline', color: 'inherit' }}>
              <img
                src={`${process.env.PUBLIC_URL}/phone.svg`}
                alt="Phone"
                style={{ marginRight: '8px', verticalAlign: 'middle' }}
              />
              +64 21 455 564
            </a>
          </Heading>
          <Paragraph textAlign="center">
            <Button
              onClick={() => window.location.href = 'tel:+6421455564'}
              appearance="primary"
              intent="none"
              style={{
                backgroundColor: '#FFA726',
                color: '#FFF',
                padding: '20px 20px',
                borderRadius: '8px',
                textDecoration: 'none',
                fontSize: '12px',
                border: 'none',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                marginBottom: '20px',
                width: '100%',  // Makes the button fill the entire width of its container
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#e67300'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#FFA726'}
            >
              Tap to call
            </Button>
          </Paragraph>
        </Pane>
      </Dialog>
    </div>
  );
}
