import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Make sure to import axios
import '../HomePage/HomePage.css'; // Corrected the import path
import Header from '../../components/header';
import Footer from '../../components/Footer';
import { TextInputField, SelectField, TextareaField, Button, Heading, Text, Pane, ChevronDownIcon, ChevronUpIcon } from "evergreen-ui";
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'react-collapse';  // For collapsible sections

// Define the teamMembers array here
const teamMembers = [
  {
    name: "Thomas Price",
    title: "Head DirtBag",
    description: "Qualified builder / developer in Dunedin",
  },
  {
    name: "Jack H",
    title: "Product DirtBag",
    description: "Ensures our product runs smoothly.",
  },
  {
    name: "Jesse Price",
    title: "Head Geologist DirtBag",
    description: "Expert in site assessments and geological evaluations.",
  },
  {
    name: "Ben",
    title: "Lead DirtBag",
    description: "Qualified builder based in Dunedin.",
  }
];

const services = [
  {
    name: "Material Removal & Supply",
    content: [
      "Efficiently manage the delivery and removal of materials to and from your site.",
      "Receive timely removal and supply services based on your project needs.",
      "Cost-effective and reliable services for material supply and removal."
    ],
    link: '/materials'
  },
  {
    name: "Site Assessment & Quoting",
    content: [
      "Receive accurate site evaluations and customized quotes.",
      "Our professionals perform HAIL testing to ensure site safety.",
      "We provide detailed reports on potential risks and costs."
    ],
    link: '/materials'
  },
  {
    name: "Equipment Supply",
    content: [
      "We supply loaders and diggers for your project’s efficiency.",
      "Our equipment rental services include skilled operators."
    ],
    link: '/loading-and-transport'
  },
  {
    name: "Material Marketplace",
    content: [
      "Easily buy and sell materials through our dedicated marketplace.",
      "Access a wide range of materials at competitive prices."
    ],
    link: '/materials'
  },
  {
    name: "HAIL Testing",
    content: [
      "Expert HAIL testing services for land contamination analysis.",
      "Comprehensive testing reports for regulatory compliance."
    ],
    link: '/hail-testing'
  },
  {
    name: "Recycling Services",
    content: [
      "Providing eco-friendly recycling services for building materials.",
      "Minimize construction waste with our recycling solutions."
    ],
    link: '/recycling'
  },
  {
    name: "Loading & Transport",
    content: [
      "Manage your site’s loading and transport needs with our services.",
      "Receive quotes from multiple providers to find the best solution."
    ],
    link: '/loading-and-transport'
  },
  {
    name: "Demolition Services",
    content: [
      "Professional material-related demolition services.",
      "Efficiently and safely demolish structures while managing waste."
    ],
    link: '/demolition'
  }
];

const ContactPage = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formData, setFormData] = useState({
    fullName: '',
    address: '',
    email: '',
    phoneNumber: '',
    typeOfWork: '',
    notes: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activeService, setActiveService] = useState(null);  

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
        const response = await axios.post('http://localhost:4000/send-email', formData);
        setSuccessMessage('Your request has been submitted successfully!');
        setFormData({
            fullName: '',
            address: '',
            email: '',
            phoneNumber: '',
            typeOfWork: '',
            notes: ''
        });
    } catch (error) {
        setErrorMessage('There was an error submitting your request. Please try again.');
        console.error('Submission error:', error);
    } finally {
        setLoading(false);
    }
  };

  const handleLearnMore = (link) => {
    navigate(link);
  };

  const handleContactUs = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleService = (serviceName) => {
    setActiveService((prevService) => (prevService === serviceName ? null : serviceName));
  };

  const renderServiceContent = (service) => {
    const buttonStyle = {
      backgroundColor: '#FFA236',
      color: '#FFFFFF',
      padding: '8px 23px',
      borderRadius: '8px',
      fontSize: '13px',
      fontWeight: '400',
      border: 'none',
      marginTop: '20px',
      textAlign: 'center',
      cursor: 'pointer',
      display: 'block',
      width: '100%',
    };

    const contactButtonStyle = {
      ...buttonStyle,
      backgroundColor: 'transparent',
      color: '#FFA236',
      border: '1px solid #FFA236',
    };

    return (
      <Pane>
        <ul style={{ paddingLeft: '0', listStyle: 'none', margin: '20px 0' }}>
          {service.content.map((point, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <span style={{
                marginRight: '10px',
                backgroundColor: '#e1f5f3',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="8" cy="8" r="8" fill="#E0F7FA" />
                  <path d="M6.5 9.5L4.5 7.5L5.5 6.5L6.5 7.5L10.5 3.5L11.5 4.5L6.5 9.5Z" fill="#00695C" />
                </svg>
              </span>
              <Text size={400}>{point}</Text>
            </li>
          ))}
        </ul>
        <div style={{
          display: windowWidth <= 768 ? 'block' : 'flex',
          justifyContent: 'flex-start',
          gap: '10px',
          marginTop: '20px'
        }}>
          <button
            style={{ ...buttonStyle, width: windowWidth > 768 ? 'auto' : '100%' }}
            onClick={() => handleLearnMore(service.link)}
          >
            Learn More
          </button>
          <button
            style={{ ...contactButtonStyle, width: windowWidth > 768 ? 'auto' : '100%' }}
            onClick={handleContactUs}
          >
            Contact Us
          </button>
        </div>
      </Pane>
    );
  };

  return (
    <div className="container-lg">
      <Header />
      <div className="container-lg my-4" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="hero-container" style={{
          display: 'flex',
          flexDirection: windowWidth <= 768 ? 'column' : 'row',
          justifyContent: 'space-between',
          padding: '20px',
          gap: '20px',
          flexWrap: 'wrap',
          alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
        }}>
          <div style={{
            flex: 1,
            paddingRight: windowWidth <= 768 ? '0' : '20px',
            minWidth: '300px',
            textAlign: windowWidth <= 768 ? 'center' : 'start',
            marginBottom: windowWidth <= 768 ? '20px' : '0',
          }}>
            <Heading size={1100} textAlign={windowWidth <= 768 ? 'center' : 'start'} fontWeight="light" marginBottom={15}>
              Contact Us
            </Heading>
            <Text fontSize={18} textAlign={windowWidth <= 768 ? 'center' : 'start'} color="#333" marginTop={20} marginBottom={15}>
              Dirt makes Material Management easy. List materials to sell, remove, or request. Let us know what you need or reach out!
            </Text>
          </div>
          <div style={{
            flex: 1,
            paddingLeft: windowWidth <= 768 ? '0' : '20px',
            minWidth: '300px',
          }}>
            <form onSubmit={handleSubmit} style={{
              backgroundColor: '#FFFFFF',
              padding: '10px 20px',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '100%',
              margin: '0 auto',
              display: 'block',
            }}>
              {loading && <p>Loading...</p>}
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
              <TextInputField
                placeholder="Full Name *"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                marginBottom={16}
                marginTop={20}
                width="100%"
                required
              />
              <TextInputField
                placeholder="Address *"
                name="address"
                value={formData.address}
                onChange={handleChange}
                marginBottom={16}
                width="100%"
                required
              />
              <TextInputField
                placeholder="Email *"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                marginBottom={16}
                width="100%"
                required
              />
              <TextInputField
                placeholder="Phone Number *"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                marginBottom={16}
                width="100%"
                required
              />
              <SelectField
                name="typeOfWork"
                value={formData.typeOfWork}
                onChange={handleChange}
                marginBottom={16}
                width="100%"
                required
              >
                <option value="" disabled>Select Type of Work</option>
                <option value="material supply">Material Supply</option>
                <option value="material removal">Material Removal</option>
                <option value="loading & transporting">Loading & Transporting</option>
                <option value="demolition">Demolition</option>
                <option value="recycling">Recycling</option>
                <option value="hail testing">Hail Testing</option>
                <option value="other">Other</option>
              </SelectField>
              <TextareaField
                placeholder="Any additional information or notes..."
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                marginBottom={16}
                width="100%"
              />
              <Button
                appearance="primary"
                backgroundColor="#FFA236"
                color="#FFFFFF"
                paddingY={20}
                paddingX={40}
                borderRadius={8}
                fontSize={11}
                fontWeight={300}
                border="none"
                width="100%"
                disabled={loading}
                onMouseOver={(e) => e.target.style.backgroundColor = '#FF8A00'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#FFA236'}
              >
                {loading ? 'Submitting...' : 'Submit Request'}
              </Button>
            </form>
          </div>
        </div>
      </div>

      {/* The Contact Process Section */}
      <div className="core-values-container my-4">
        <h2>The Contact Process</h2>
        <div className="core-values-content text-start">
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-listing.svg`} alt="Sign Up" />
            <h3>Sign Up</h3>
            <p>Start by providing your contact details, including your name, email, and phone number.</p>
          </div>
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-profile.svg`} alt="Provide Details" />
            <h3>Provide Details</h3>
            <p>Let us know the specifics of your inquiry—whether you need materials, have a request, or need assistance.</p>
          </div>
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-transport.svg`} alt="Get a Response" />
            <h3>Get a Response</h3>
            <p>Once your form is submitted, our team will review and get in touch with you promptly to assist with your needs.</p>
          </div>
        </div>
      </div>

      {/* Our Services Section */}
      <div className="hero-container" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF5EA',
        padding: '40px 20px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '15px',
      }}>
        <div className="container-lg my-4" style={{ width: '100%' }}>
          <Heading size={800} textAlign="center" fontWeight="500" marginBottom={24}>
            Our Services
          </Heading>
          <Pane>
            {services.map((service, index) => (
              <Pane
                key={index}
                background="white"
                border="muted"
                padding={16}
                borderRadius={12}
                marginBottom={16}
                style={{ backgroundColor: '#fff', border: '1px solid #ddd' }}
              >
                <Pane onClick={() => toggleService(service.name)} display="flex" justifyContent="space-between" alignItems="center" cursor="pointer">
                  <Heading size={600} fontWeight="500">{service.name}</Heading>
                  {activeService === service.name ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Pane>
                <Collapse isOpened={activeService === service.name}>
                  {renderServiceContent(service)}
                </Collapse>
              </Pane>
            ))}
          </Pane>
        </div>
      </div>

      {/* Team Section */}
      <div className="container-lg my-5">
        <Heading size={800} fontWeight="light" textAlign="center" marginBottom={24}>
          Meet Our DirtBags
        </Heading>
        <Pane display="flex" flexWrap="wrap" justifyContent="center">
          {teamMembers.map((member, index) => (
            <Pane
              key={index}
              background="white"
              border="muted"
              padding={16}
              borderRadius={12}
              margin={16}
              style={{
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                textAlign: 'center',
                width: window.innerWidth <= 768 ? '100%' : '45%',
              }}
            >
              <Heading size={700} fontWeight="bold" marginBottom={12}>
                {member.name}
              </Heading>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text size={600} color="#FFA236" fontWeight="bold" marginBottom={8}>
                  {member.title}
                </Text>
                <Text size={400} color="#4A5568" marginBottom={16}>
                  {member.description}
                </Text>
                <div style={{ textAlign: 'start', width: '100%' }}>
                  <Button
                    appearance="primary"
                    backgroundColor="#FFA236"
                    color="#FFFFFF"
                    paddingY={20}
                    paddingX={40}
                    borderRadius={8}
                    fontSize={11}
                    fontWeight={300}
                    border="none"
                    width="100%"
                    onMouseOver={(e) => e.target.style.backgroundColor = '#FF8A00'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#FFA236'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  >
                    Contact
                  </Button>
                </div>
              </div>
            </Pane>
          ))}
        </Pane>
      </div>

      {/* Where We're Located Section */}
      <div className="container-lg my-5">
        <Heading size={800} fontWeight="light" textAlign="center">
          Where We're Located
        </Heading>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 px-4 mb-4">
            <div className="office-location styled-container">
              <img className="img img-fluid" src={process.env.PUBLIC_URL + '/dunedin-office.svg'} alt="Dunedin Office" />
              <Heading size={700} marginTop={16} fontWeight="bold">Dunedin</Heading>
              <Text>
                <a
                  href="https://www.google.com/maps?q=329+Kaikorai+Valley+Road,+Bradford,+Dunedin+9011"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#000', textDecoration: 'underline' }}
                >
                  329 Kaikorai Valley Road, Bradford, Dunedin 9011
                </a>
              </Text>
              <Text>
                PH: <a href="tel:+6421440042" style={{ color: '#000', textDecoration: 'underline' }}>+64 214 40042</a>
              </Text>
            </div>
          </div>
          <div className="col-12 col-md-6 px-4 mb-4">
            <div className="office-location styled-container">
              <img className="img img-fluid" src={process.env.PUBLIC_URL + '/auckland-office-new.svg'} alt="Auckland Office" />
              <Heading size={700} marginTop={16} fontWeight="bold">Auckland</Heading>
              <Text>
                <a
                  href="https://www.google.com/maps?q=2+Picton+Street,+Ponsonby,+Auckland+1011"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#000', textDecoration: 'underline' }}
                >
                  2 Picton Street, Ponsonby, Auckland 1011
                </a>
              </Text>
              <Text>
                PH: <a href="tel:+6421455564" style={{ color: '#000', textDecoration: 'underline' }}>+64 214 55564</a>
              </Text>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
