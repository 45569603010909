import React, { useEffect, useRef, useState, useContext } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Pane, Alert, Button, TextInput, IconButton, CrossIcon } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { DirtFormContext } from '../../../contexts/DirtFormContext';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '../index.css'; // Import the same CSS as MaterialTypePage

mapboxgl.accessToken = 'pk.eyJ1IjoiaGVhZGRpcnRiYWciLCJhIjoiY2x1NjF2MWl1MXA1ZzJrbDg2eDllOHFreCJ9.tHxWNdyXwdz_d8WD7VWwJg';

const SubmitAddressPage = () => {

    const { setFormData } = useContext(DirtFormContext);
    const navigate = useNavigate();
    const [coords, setCoords] = useState({ lng: 170.487622, lat: -45.874183 });
    const [zoom, setZoom] = useState(13);
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');

    const mapContainerRef = useRef(null);
    const geocoderContainerRef = useRef(null);
    const geocoderRef = useRef(null);
    const markerRef = useRef(null);

    useEffect(() => {
        console.log('Mapbox accessToken:', mapboxgl.accessToken);

        if (!mapboxgl.accessToken) {
            setError('Mapbox access token is missing or invalid.');
            console.error('Mapbox accessToken is invalid or not provided:', mapboxgl.accessToken);
            return;
        }

        if (!mapContainerRef.current || !geocoderContainerRef.current) return;

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [coords.lng, coords.lat],
            zoom: zoom
        });

        map.addControl(new mapboxgl.NavigationControl(), 'top-right');

        geocoderRef.current = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Search address'
        });

        if (geocoderContainerRef.current && !geocoderContainerRef.current.children.length) {
            geocoderContainerRef.current.appendChild(geocoderRef.current.onAdd(map));
        }

        // Event listener for the result from the geocoder
        geocoderRef.current.on('result', (e) => {
            const { result } = e;
            console.log('Geocoder result event:', e);

            if (result && result.center) {
                // Update coordinates and address state
                setCoords({ lng: result.center[0], lat: result.center[1] });
                setAddress(result.place_name);
                map.flyTo({ center: result.center, zoom: 15 });

                // Remove existing marker, if any
                if (markerRef.current) {
                    markerRef.current.remove();
                }

                // Add marker at the selected location
                markerRef.current = new mapboxgl.Marker()
                    .setLngLat(result.center)
                    .setPopup(
                        new mapboxgl.Popup({ offset: 25 })
                            .setHTML(`<h3>${result.text}</h3><p>${result.place_name}</p>`)
                    )
                    .addTo(map);

                setError('');
            } else {
                console.error('Geocoder did not return a valid result:', result);
                setError('No results found, please try another address.');
            }
        });

        geocoderRef.current.on('error', (error) => {
            console.error('Geocoder encountered an error:', error);
            setError('Error fetching address, please try again.');
        });

        return () => {
            map.remove();
            if (geocoderRef.current) {
                geocoderRef.current.onRemove();
            }
        };
    }, [coords.lng, coords.lat, zoom]);

    const handleClear = () => {
        setAddress('');
        setCoords({ lng: 170.487622, lat: -45.874183 });
        if (markerRef.current) {
            markerRef.current.remove();
        }
        console.log('Address cleared, reset to default coordinates:', coords);
    };

    const handleSubmit = () => {
        if (!coords) {
            setError('Please search for a valid address before submitting.');
            console.error('Submit attempt with invalid coordinates:', coords);
            return;
        }
        setFormData(prevData => ({ ...prevData, coords, address }));
        console.log('Form submitted with:', { coords, address });
        navigate('/dirt/material-type');
    };

    return (
        <div className="row">
            <div className="col text-center">
                {/* Heading row */}
                <div className="row">
                    <div className="col text-start">
                        <h2>Address</h2>
                    </div>
                </div>
                {/* Subheading row */}
                <div className="row mt-2">
                    <div className="col text-start">
                        <p>Provide the location</p>
                    </div>
                </div>
                {/* Geocoder */}
                <div className="row justify-content-start mt-4">
                    <div className="col-10">
                        <div ref={geocoderContainerRef} style={{ width: '100%' }} />
                        {address && (
                            <div className="d-flex align-items-center mt-2" style={{ gap: '8px' }}>
                                <TextInput 
                                    className="form-control"
                                    value={address}
                                    readOnly
                                    width="100%"
                                />
                                <IconButton 
                                    icon={CrossIcon} 
                                    appearance="default"
                                    intent="danger"
                                    height={20} // Adjust height for better alignment
                                    onClick={handleClear}
                                />
                            </div>
                        )}
                        {error && <Alert intent="danger" title={error} />}
                    </div>
                </div>
                {/* Map */}
                <div className="row justify-content-center mt-4">
                    <div className="col-12">
                        <Pane
                            elevation={1} // Add shadow to the map container for depth
                            height={300} // Adjust height as needed
                            border="default" // Add border around the map
                            marginTop={16} // Add margin to space it from the search area
                        >
                            <div ref={mapContainerRef} style={{ height: '100%', borderRadius: 'inherit' }} />
                        </Pane>
                    </div>
                </div>
                {/* Next button row */}
                <div className="row mt-4 justify-content-start">
                <div className="col-12 col-md-4 custom-width-35">
                        <Button className="btn btn-warning w-100" onClick={handleSubmit}>
                            Next <span className="ml-2"></span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubmitAddressPage;
