import '../index.css';
import { useState, useContext, useEffect } from 'react';
import { Button, TextInputField } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import HelpLink from '../../../components/HelpLink';
import { DirtFormContext } from '../../../contexts/DirtFormContext';

const ContactInformationPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const navigate = useNavigate();
  const { setFormData } = useContext(DirtFormContext);

  // Function to handle form submission
  const handleSubmit = () => {
    setFormData(prevData => ({
      ...prevData,
      firstName,
      lastName,
      businessName,
      email,
      contactNumber,
    }));
    navigate('/dirt/confirm');
  };

  // Add a class to adjust layout when keyboard is visible (on mobile)
  const handleFocus = (event) => {
    const inputField = event.target;
    setTimeout(() => {
      inputField.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
    document.body.classList.add('keyboard-visible');
  };

  const handleBlur = () => {
    document.body.classList.remove('keyboard-visible');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Add event listeners to manage focus/blur dynamically
  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    // Cleanup event listeners
    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, []);

  return (
    <div className="row">
      <div className="col text-center">
        {/* Heading row */}
        <div className="row">
          <div className="col text-start">
            <h2>Contact Information</h2>
          </div>
        </div>
        {/* Subheading row */}
        <div className="row mt-2">
          <div className="col text-start">
            <p>Provide your contact information so we can follow up on your listing.</p>
          </div>
        </div>
        {/* Form Inputs */}
        <div className="row mt-4">
          <div className="col text-start">
            <TextInputField
              label="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="First Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-start">
            <TextInputField
              label="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-start">
            <TextInputField
              label="Business Name (optional)"
              value={businessName}
              onChange={e => setBusinessName(e.target.value)}
              placeholder="Business Name (optional)"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-start">
            <TextInputField
              label="Email Address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-start">
            <TextInputField
              label="Best Contact Number"
              value={contactNumber}
              onChange={e => setContactNumber(e.target.value)}
              placeholder="Best Contact Number"
            />
          </div>
        </div>
        {/* Help link */}
        <div className="row mt-4">
          <div className="col text-start">
            <HelpLink onClick={() => console.log('Help clicked')} />
          </div>
        </div>
        {/* Next button row */}
        <div className="row mt-4 justify-content-start">
          <div className="col-12 col-md-4 custom-width-35">
            <Button className="btn btn-warning w-100" onClick={handleSubmit}>
              Next 
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformationPage;
