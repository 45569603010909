// client/src/App.jsx

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import Providers
import { AuthProvider } from './contexts/AuthContext.jsx';
import { AppProvider } from './contexts/AppContext';
import { DirtFormProvider } from './contexts/DirtFormContext';

// Import Components and Pages with Correct Paths
import Footer from './components/Footer';

// DirtForm Pages
import DirtForm from './views/DirtForm';
import SelectionPage from './views/DirtForm/SelectionPage/index.jsx';
import BookingDatePage from './views/DirtForm/BookingDatePage/index.jsx';
import ConfirmRequestPage from './views/DirtForm/ConfirmRequestPage/index.jsx';
import MaterialTypePage from './views/DirtForm/MaterialTypePage/index.jsx';
import SubmitAddressPage from './views/DirtForm/SubmitAddressPage/index.jsx';
import UploadImagesPage from './views/DirtForm/UploadImagesPage/index.jsx';
import ContactInformationPage from './views/DirtForm/ContactInformationPage/index.jsx';

// Listings Pages
import ListingLayout from './views/Listings/index.jsx';
import AllListingsPage from './views/Listings/AllListingsPage/AllListingsPage.jsx';
import ListingPage from './views/Listings/ListingsPage/index.jsx';

// Other Pages
import HomePage from './views/HomePage/index.jsx';
import ContactPage from './views/ContactPage/ContactPage.jsx';
import MaterialsPage from './views/MaterialsPage/MaterialsPage.jsx';

// Additional Pages
import LoadingAndTransport from './views/LoadingAndTransport/LoadingAndTransport.jsx';
import Demolition from './views/Demolition/Demolition.jsx';
import Recycling from './views/Recycling/Recycling.jsx';
import HailTesting from './views/HailTesting/HailTesting.jsx';
import TermsOfUse from './views/TermsOfUse/TermsOfUse.jsx';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy.jsx';

// Auth Pages
import LoginPage from './views/DirtForm/Login.jsx/index.jsx';
import SignUpPage from './views/DirtForm/SignUpPage/SignUpPage.jsx';

// Import ProtectedRoute
import ProtectedRoute from './components/ProtectedRoute.jsx';

// Import CSS
import './App.css';
import mapboxgl from 'mapbox-gl'; // Ensure this is used or remove if unnecessary

// Define the router with correct paths and protected routes
const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'dirt',
    element: <DirtForm />,
    children: [
      { path: 'list', element: <SelectionPage /> },
      { path: 'material-type', element: <MaterialTypePage /> },
      { path: 'date', element: <BookingDatePage /> },
      { path: 'address', element: <SubmitAddressPage /> },
      { path: 'images', element: <UploadImagesPage /> },
      { path: 'contact', element: <ContactInformationPage /> },
      { path: 'confirm', element: <ConfirmRequestPage /> },
    ],
  },
  {
    path: 'about',
    element: <ContactPage />,
  },
  {
    path: 'materials',
    element: <MaterialsPage />,
  },
  {
    path: 'listings',
    element: (
      <ProtectedRoute>
        <ListingLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: '', element: <AllListingsPage /> },
      { path: ':listingId', element: <ListingPage /> },
    ],
  },
  // New routes for the additional pages
  {
    path: 'loading-and-transport',
    element: <LoadingAndTransport />,
  },
  {
    path: 'demolition',
    element: <Demolition />,
  },
  {
    path: 'recycling',
    element: <Recycling />,
  },
  {
    path: 'hail-testing',
    element: <HailTesting />,
  },
  // Terms of Use and Privacy Policy routes
  {
    path: 'terms-of-use',
    element: <TermsOfUse />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  // Add the login and signup routes
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'signup',
    element: <SignUpPage />,
  },
  // Fallback route for 404 Not Found
  {
    path: '*',
    element: <p>Not Found</p>,
  },
]);

function App() {
  return (
    <div className="app">
      <AppProvider>
        <AuthProvider>
          <DirtFormProvider>
            <RouterProvider router={router} />
          </DirtFormProvider>
        </AuthProvider>
      </AppProvider>
      {/* Optionally include Footer if it's meant to be displayed on all pages */}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
