import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import { TextInputField, SelectField, TextareaField, Button, Heading, Text } from "evergreen-ui";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const RecyclingPage = () => {

  const navigate = useNavigate();
  
  // State to control phone popup visibility
  const [isPhonePopupVisible, setIsPhonePopupVisible] = useState(false);

  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Form state
  const [formData, setFormData] = useState({
    address: '',
    fullName: '', // Consolidating firstName and lastName into fullName
    email: '',
    phoneNumber: '',
    typeOfWork: '',
    notes: '',  
    description: ''
  });

  // State to track touched fields (for validation)
  const [touchedFields, setTouchedFields] = useState({
    address: false,
    fullName: false,
    email: false,
    phoneNumber: false,
    typeOfWork: false,
  });

  // Form submission state
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle form field changes
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true, // Mark the field as touched when user starts typing
    }));
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    // Track which fields are touched (i.e., left empty)
    const updatedTouchedFields = {
      fullName: !formData.fullName,
      address: !formData.address,
      email: !formData.email,
      phoneNumber: !formData.phoneNumber,
      typeOfWork: !formData.typeOfWork,
    };
    setTouchedFields(updatedTouchedFields);

    // If any required field is empty, don't submit the form
    if (Object.values(updatedTouchedFields).some(field => field)) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('http://localhost:4000/send-email', formData);
      setSuccessMessage('Your request has been submitted successfully!');
      setFormData({
        address: '',
        fullName: '',
        email: '',
        phoneNumber: '',
        typeOfWork: '',
        notes: '',
        description: '',
      });
    } catch (error) {
      setErrorMessage('There was an error submitting your request. Please try again.');
      console.error('Submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  /// Function to open mail client
  const handleEmailClick = () => {
    window.location.href = 'mailto:jack@dirt.nz';
  };

  /// Function to open phone popup
  const handleCallClick = () => {
    setIsPhonePopupVisible(true);
  };

  /// Function to close phone popup
  const closePhonePopup = () => {
    setIsPhonePopupVisible(false);
  };

  /// Style objects
const wrapperStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  padding: '100px',
  gap: '25px',
  padding: '0 20px', // added padding for better mobile responsiveness
};

const tileStyle = {
  flex: '1 1 250px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  padding: '30px',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginTop: '30px',
  marginBottom: '30px',
  transition: 'transform 0.3s ease',
  width: '100%',
  flex: '1 1 45%',
};

const mobileTileStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5px',
  width: '90%',
  maxWidth: '100%',
  margin: '0 auto',
};

const buttonStyle = {
  backgroundColor: '#FFA236',
  color: '#FFFFFF',
  padding: '20px 35px',
  borderRadius: '8px',
  fontSize: '15px',
  fontWeight: '400',
  border: 'none',
  marginTop: '25px',
  width: '85%',
  textAlign: 'center',
};

const pageLayoutStyle = {
  display: 'flex',
  flexDirection: windowWidth <= 768 ? 'column' : 'row',  // Adjust based on window width
  justifyContent: 'space-between',
  padding: '20px',
  gap: '20px',
  flexWrap: 'wrap',
  alignItems: windowWidth <= 768 ? 'center' : 'flex-start',
};

const leftSectionStyle = {
  flex: 1,
  paddingRight: windowWidth <= 768 ? '0' : '20px',
  minWidth: '300px',
  textAlign: windowWidth <= 768 ? 'center' : 'start',
  marginBottom: windowWidth <= 768 ? '20px' : '0',
};

const rightSectionStyle = {
  flex: 1,
  paddingLeft: windowWidth <= 768 ? '0' : '20px',
  minWidth: '300px',
};

// Phone popup style
const popupStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  textAlign: 'center',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 999,
};
const formContainerStyle = {
  backgroundColor: '#FFFFFF',
  padding: '10px 20px',  // Reduced padding for a tighter look
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: windowWidth <= 768 ? '100%' : '100%',
  maxWidth: '100%',
  margin: '0 auto',
  display: 'block',
};

  return (
    <div className="container-lg">
      <Header />
  
      <div className="container-lg my-4" style={{ backgroundColor: '#FFFFFF' }}>
        <div className="hero-container" style={pageLayoutStyle}>
          {/* Left section (image and heading) */}
          <div style={leftSectionStyle}>
            <div style={{ display: 'flex', justifyContent: windowWidth <= 768 ? 'center' : 'flex-start', alignItems: 'center' }}>
              {/* Center the button on mobile */}
              <img 
                src={`${process.env.PUBLIC_URL}/recycling.svg`} 
                alt="Recycling Logo" 
                style={{ width: '200px', height: '50px', marginBottom: '20px', marginLeft: windowWidth <= 768 ? '0' : '0' }} 
              />
            </div>
  
            <div style={{ marginLeft: '0', textAlign: windowWidth <= 768 ? 'center' : 'start' }}>
              <Heading size={1100} textAlign={windowWidth <= 768 ? 'center' : 'start'} fontWeight="light" marginBottom={15}>
                Building Site Material Recycling Made Simple
              </Heading>
  
              <Text fontSize={18} textAlign={windowWidth <= 768 ? 'center' : 'start'} color="#333" marginTop={20} marginBottom={15}>
                Help us make a positive impact by recycling your materials responsibly. Get quotes and manage your recycling process with ease.
              </Text>
            </div>
          </div>
  
          {/* Right section (form) */}
          <div style={rightSectionStyle}>
            {!requestSubmitted && (
              <div style={formContainerStyle}>
                {loading && <p>Loading...</p>}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                <form onSubmit={handleSubmit}>
                  <TextInputField
                    label="Full Name *"
                    placeholder="Enter your full name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    isInvalid={touchedFields.fullName && !formData.fullName}
                    validationMessage={touchedFields.fullName && !formData.fullName ? 'Full Name is required' : null}
                    marginBottom={16}
                    marginTop={20}
                    width="100%"
                  />
  
                  <TextInputField
                    label="Address *"
                    placeholder="Enter your address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    isInvalid={touchedFields.address && !formData.address}
                    validationMessage={touchedFields.address && !formData.address ? 'Address is required' : null}
                    marginBottom={16}
                    width="100%"
                  />
  
                  <TextInputField
                    label="Email *"
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={touchedFields.email && !formData.email}
                    validationMessage={touchedFields.email && !formData.email ? 'Email is required' : null}
                    marginBottom={16}
                    width="100%"
                  />
  
                  <TextInputField
                    label="Phone Number *"
                    placeholder="Enter your phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    isInvalid={touchedFields.phoneNumber && !formData.phoneNumber}
                    validationMessage={touchedFields.phoneNumber && !formData.phoneNumber ? 'Phone Number is required' : null}
                    marginBottom={16}
                    width="100%"
                  />
  
                  <SelectField
                    label="Type of Work *"
                    name="typeOfWork"
                    value={formData.typeOfWork}
                    onChange={handleChange}
                    isInvalid={touchedFields.typeOfWork && !formData.typeOfWork}
                    validationMessage={touchedFields.typeOfWork && !formData.typeOfWork ? 'Type of Work is required' : null}
                    marginBottom={16}
                    width="100%"
                  >
                    <option value="" disabled>
                      Select Type of Work
                    </option>
                    <option value="material supply">Material Supply</option>
                    <option value="material removal">Material Removal</option>
                    <option value="loading & transporting">Loading & Transporting</option>
                    <option value="demolition">Demolition</option>
                    <option value="recycling">Recycling</option>
                    <option value="other">Other</option>
                  </SelectField>
  
                  <TextareaField
                    label="Any additional information or notes"
                    placeholder="Enter additional details"
                    name="notes"
                    value={formData.notes}
                    onChange={handleChange}
                    marginBottom={16}
                    width="100%"
                  />
  
                  <Button
                    appearance="primary"
                    backgroundColor="#FFA236"
                    color="#FFFFFF"
                    paddingY={20}
                    paddingX={40}
                    borderRadius={8}
                    fontSize={11}
                    fontWeight={300}
                    border="none"
                    width="100%"  
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Submit Request'}
                  </Button>
                </form>
              </div>
            )}
          </div>
        </div>

      </div>

      {/* The Recycling Process Section */}
      <div className="core-values-container my-4">
        <h2>The Recycling Process</h2>
        <div className="core-values-content text-start">
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-listing.svg`} alt="Works out of the box" />
            <h3>Sign Up</h3>
            <p>Start by entering a few details about yourself or your company</p>
          </div>
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-profile.svg`} alt="Flexible and composable" />
            <h3>Request Recycling</h3>
            <p>Let us know the materials you want to recycle, and we’ll take care of the rest.</p>
          </div>
          <div className="core-value">
            <img src={`${process.env.PUBLIC_URL}/dirt-transport.svg`} alt="Enterprise-grade" />
            <h3>Sustainable Practices</h3>
            <p>We ensure that your materials are recycled responsibly, following industry standards and environmentally friendly methods.</p>
          </div>
        </div>
      </div>

      {/* Recycling Background Section */}
      <div className="dirt-background-section">
        <img
          src={`${process.env.PUBLIC_URL}/dirt-background.svg`}
          alt="Dirt Background"
          className="dirt-background-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/sale-puchase-button.svg`}
          alt="Sale Purchase Button"
          className="material-requirements-button"
        />
        <img
          src={`${process.env.PUBLIC_URL}/selection-card-dirt-requests.svg`}
          alt="Selection Card Dirt Requests"
          className="selection-card"
        />
        <h1 className="heading-text">Building Site Material Recycling</h1>
      </div>

{/* Get in Touch Section */}
<div className="container-lg my-4" style={{ marginTop: '100px', textAlign: 'center' }}>
  <div className="container-lg" style={{ maxWidth: '800px', margin: '0 auto', marginTop: '120px' }}>
    <Heading size={800} textAlign="center" marginBottom={10} fontWeight="light">
      Get in touch
    </Heading>
    <Text fontSize={16} textAlign="center" color="#666" marginTop={20} marginBottom={20}>
      Here to help with your material recycling needs. Reach out with any questions.
    </Text>
  </div>
    {/* Add extra space at the bottom */}
    <p style={{ marginBottom: '40px' }}></p>

  <div style={wrapperStyle}>
    <div style={{ ...tileStyle, ...mobileTileStyle }}>
      <Heading size={600} marginBottom={16} fontWeight="light">
        Email us
      </Heading>
      <Text marginBottom={16}>Send us an email. We'll be in touch.</Text>
      <Button style={buttonStyle} onClick={handleEmailClick}>Email us</Button>
    </div>

    <div style={{ ...tileStyle, ...mobileTileStyle }}>
      <Heading size={600} marginBottom={16} fontWeight="light">
        Recycling Request
      </Heading>
      <Text marginBottom={16}>We'll review & get in contact.</Text>
      <Button style={buttonStyle} onClick={() => navigate('/dirt/list')}>Submit Here</Button>
    </div>

    <div style={{ ...tileStyle, ...mobileTileStyle }}>
      <Heading size={600} marginBottom={16} fontWeight="light">
        Visit us
      </Heading>
      <Text marginBottom={16}>Visit our office HQ.</Text>
      <Button style={buttonStyle} onClick={() => navigate('/about#contact')}>Get directions</Button>
    </div>

    <div style={{ ...tileStyle, ...mobileTileStyle }}>
      <Heading size={600} marginBottom={16} fontWeight="light">
        Call us
      </Heading>
      <Text marginBottom={16}>Mon-Fri from 8am to 5pm.</Text>
      <Button style={buttonStyle} onClick={handleCallClick}>Call our team</Button>
    </div>
  </div>

{/* Phone Popup */}
{isPhonePopupVisible && (
  <>
    <div style={overlayStyle} onClick={closePhonePopup}></div>
    <div style={{ 
        ...popupStyle, 
        padding: '30px',  
        width: '350px',   
        textAlign: 'center'
      }}>
      <Heading size={500} marginBottom={20}>Call a Team Member to Discuss Recycling Services</Heading>
      <Text fontSize={18} color="#333" marginBottom={20}>
      <a href="tel:+6421455564" style={{ textDecoration: 'underline', color: '#FFA236', marginBottom: '60px' }}>
  +6421455564
</a>

      </Text>
      <Button 
        style={{
          backgroundColor: '#FFA236', 
          color: '#FFFFFF', 
          padding: '15px 30px', 
          borderRadius: '8px',
          fontSize: '16px',
          fontWeight: '500',
          border: 'none',
          width: '100%',
          cursor: 'pointer',
        }} 
        onClick={() => window.location.href = 'tel:+6421455564'}
      >
        Tap to Call
      </Button>
    </div>
    </>
  )}
</div>

      <Footer />
    </div>
  );
};

export default RecyclingPage;
