// src/views/HomePage/index.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Pane, Text, Heading, Button, Card, majorScale, minorScale } from 'evergreen-ui';
import './HomePage.css';
import YellowDigger from '../../components/YellowDigger';
import { Dirt } from './Dirt'; // Ensure the correct import path


export default function HomePage() {
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };

    return (
        <Pane>

            {/* Dirt Component Integration */}
            <Dirt />

            {/* Core Values Section */}
         
        </Pane>
    );
}
